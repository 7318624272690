import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import legend from 'd3-svg-legend';
import moment from 'moment';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';

//import GlobalContext from '../../GlobalContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import { getContentTypeColor, getScale, getPostTypeColor, getPostTypeScale } from '../../themes/CommonColors';

// graph size configuration
const margin = {
  top: 10,
  bottom: 25,
  left: 50,
  right: 200,
};
const width = 900 - margin.left - margin.right;
const height = 200 - margin.top - margin.bottom;

function StrategySpotterBottom(props) {
  //const GLOBAL = useContext(GlobalContext),
  const d3Container = useRef(null);

  const {graphData, filters, dateKeys, currentBrand, timeScale} = props;

  const location = useLocation();

  const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;

  // clean up event listeners on unmount
  useEffect(() => {
    //console.log(location);
    return () => {
      d3.select(window).on(`resize.strategy_spotter_bottom`, null);
    }
  }, [location])

  // draw stacked bar chart (bottom graph)
  useEffect(() => {
    d3.select(d3Container.current).select('svg').remove();
    if (graphData.length > 0 && dateKeys && filters && currentBrand && timeScale) {
      //console.log('raw stacked graph data: ', graphData);
      let show = filters.showMe,
        ctFilters = filters.contentTypes.filter(d => d.active).map(d => d.label),
        pcFilters = filters.productCategories.filter(d => d.active).map(d => d.label),
        ptFiltersRaw = filters.postTypes.filter(d => d.active).map(d => d.label),
        ptFilters = [];

      // break down post type filters
      ptFiltersRaw.forEach(f => {
        switch (f) {
          case 'Status':
            ptFilters.push('FB Status', 'TW Status', 'Retweet TW Status');
            break;
          case 'Link':
            ptFilters.push('FB Link', 'TW Link', 'Retweet TW Link', 'FB Event');
            break;
          case 'Photo':
            ptFilters.push('FB Photo', 'TW Photo', 'Retweet TW Photo', 'IN Photo', 'IN Image', 'IN Carousel_album', 'IG Photo', 'IG Image', 'IG Carousel_album');
            break;
          case 'Video':
            ptFilters.push('YT Video', 'FB Video', 'IN Video', 'IG Video');
            break;
          default:
            ptFilters.push('FB Status', 'TW Status', 'Retweet TW Status');
        }
      })

      //console.log('filters:', show, ctFilters, pcFilters, ptFilters);

      // filter data by content type, product category, and post type
      let filteredData = graphData.filter(f => 
        ctFilters.indexOf(f.content_type) >= 0 &&
        pcFilters.indexOf(f.product_service) >= 0 &&
        ptFilters.indexOf(f.post_type) >= 0);

      //console.log('filtered data: ', filteredData);

      let showField;
      switch (show) {
        case 'Content Type':
          showField = 'content_type';
          break;
        case 'Product Category':
          showField = 'product_service';
          break;
        case 'Post Type':
          showField = 'post_type';
          break;
        default: 
          showField = 'content_type';
      }

      let scaler = (date) => {
        let parseDate = moment.utc(date).toDate();
        switch (timeScale) {
          case 'daily':
            return date;
          case 'weekly':
            return moment.utc(d3.utcWeek(parseDate)).format('YYYY-MM-DD');
          case 'monthly':
            return moment.utc(d3.utcMonth(parseDate)).format('YYYY-MM-DD');
          default:
            return date;
        }
      }

      let nestedData = d3.nest()
        .key(d => scaler(d.date))
        .key(d => d[showField])
        .rollup(d => d3.sum(d, m => m.count))
        .entries(filteredData)

      //console.log('nested data: ', nestedData);

      // group keys for legend
      let groupKeys = [...new Set(filteredData.map(m => m[showField]))];

      drawStackedGraph(nestedData, showField, groupKeys, dateKeys);
    }
  }, [graphData, dateKeys, filters, currentBrand, timeScale])

  function drawStackedGraph(data, show, groupKeys, dateKeys) {
    //console.log('stacked: ', data, show, groupKeys, dateKeys);

    // draw svg
    d3.select(d3Container.current).select('svg').remove();
    let svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', getCurrentWidth() + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit');

    let numTicks = data.length;

    let bandwidth = (getCurrentWidth() / numTicks) - 25;
    if (bandwidth > 30) bandwidth = 30;

    // draw axes
    let x = d3.scaleUtc()
      .domain(d3.extent(data, d => moment.utc(d.key).toDate()))
      .range([0, getCurrentWidth()]);
    /* d3.axisBottom(x)
      .ticks(numTicks)
      .tickFormat(d3.utcFormat("%b %d")); */

    let y = d3.scaleLinear()
      .domain([0, 1])
      .range([height, 0]);

    // define stack function
    let stack = d3.stack()
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetExpand);

    let dateGroups = [...new Set(data.map(d => moment.utc(d.key).toDate()))];
    let series = svg.selectAll('.series')
      .data(dateGroups)
      .join('g')
        .attr('class', 'stack')
        .attr('id', d => moment.utc(d).format('Y-MM-DD').toString())
    
    series.selectAll('rect')
      .data(d => {
        let rectData = data.find(f => f.key === moment.utc(d).format('Y-MM-DD').toString()).values;
        let keys = rectData.map(d => d.key).sort();
        let flattened = {};
        keys.forEach(key => Object.assign(flattened, {[key]: rectData.find(f => f.key === key).value}));
        //console.log('flattened: ', flattened);
        let stacked = stack.keys(keys)([flattened]);
        //console.log('stacked:', stacked);
        return stacked;
      })
      .join('rect')
        .attr('class', 'dataRect')
        .attr('height', d => {return y(d[0][0]) - y(d[0][1]);})
        .attr('width', bandwidth)
        .attr('x', (d, i, n) => x(moment.utc(n[i].parentNode.id).toDate()) - (bandwidth/2))
        .attr('y', d => y(d[0][1]))
        .attr('fill', d => {
          //console.log(d, show);
          if (show === 'content_type' || show === 'product_service') return getContentTypeColor(d.key)
          else if (show === 'post_type') return getPostTypeColor(d.key)
        })
        .on('click', clickHandle)
        .on('mouseover', tooltipOpen)
        .on('mousemove', tooltipMove)
        .on('mouseout', tooltipClose);

    let sbScale;
    if (show === 'content_type' || show === 'product_service') sbScale = getScale(groupKeys);
    else if (show === 'post_type') sbScale = getPostTypeScale(groupKeys) 
    //console.log('sb group keys:', groupKeys, sbScale)

    // draw legend
    svg.append('g')
      .attr('class', 'legend')
      .attr('id', 'sbStackedLegend');
    let colorLegend = legend.legendColor()
      .scale(sbScale)
      .labelFormat(d3.format(".2f"));
    let legendNode = svg.select("#sbStackedLegend")
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', `translate(${margin.left + getCurrentWidth() + 10},${height / 2 - legendHeight / 2})`);

    // y-axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("fill", "currentColor")
      .text(props.filters.showMe);

      const resize = () => {
        console.log('resize triggered');
        const updateSvg = d3.select(d3Container.current)
          .select('svg')
          .transition()
            .duration(500);
        
        updateSvg.attr('width', getCurrentWidth() + margin.left + margin.right)
  
        x.range([0, getCurrentWidth()]);
        
        bandwidth = (getCurrentWidth() / numTicks) - 25;
        if (bandwidth > 30) bandwidth = 30;
  
        updateSvg.selectAll('.dataRect')
          .attr('width', bandwidth)
          .attr('x', (d, i, n) => x(moment.utc(n[i].parentNode.id).toDate()) - (bandwidth/2))
  
        updateSvg.select('.legend')
          .attr('transform', 'translate(' + (margin.left + getCurrentWidth() + 10) + ',' + (height / 2 - legendHeight / 2) + ')');
      }
  
      d3.select(window).on(`resize.strategy_spotter_bottom`, debounce(resize, 300)) 
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this),
      contentType = d.key,
      numPosts = d[0].data[contentType],
      share = Math.round((d[0][1] - d[0][0]) * 100),
      date = moment.utc(n[i].parentNode.id).format('MMM DD')

    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Date: </span>${date}</div>
                <div class="tooltipLine"><span style="font-weight: bold">${props.filters.showMe}: </span>${contentType}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Posts: </span>${numPosts}</div>
                <div class="tooltipLine"><span style="font-weight: bold">% of Feed: </span>${share}%</div>
              </div>`
        );
    
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y)
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y)
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  function clickHandle(d, i, n) { 
    let contentType = d.key;
    let date = n[i].parentNode.id;
    let startDate,
      endDate;

    if (timeScale === 'daily') {
      startDate = moment.utc(date);
      endDate = moment.utc(date);
    } 
    else if (timeScale === 'weekly') {
      startDate = d3.utcWeek(moment.utc(date));
      endDate = moment.utc(startDate).add(6, 'days');
    }
    else if (timeScale === 'monthly') {
      startDate = d3.utcMonth(moment.utc(date));
      endDate = moment.utc(startDate).add(1, 'months');
    }  

    //console.log(currentBrand, contentType, date);
    props.openModal(currentBrand, startDate, endDate, contentType);
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
      {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
      <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  )
}

export default StrategySpotterBottom;