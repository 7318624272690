import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import { 
  withStyles,
  Typography,
  Snackbar, 
  IconButton,
  FormControlLabel,
  Switch,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Checkbox,
  Box,
  Button,
  ListItemSecondaryAction,
  Divider,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import GlobalContext from './GlobalContext';
import { updateFocusedInput } from './redux/actions/index';
import CustomTooltip from './CustomTooltip';

function GlobalFilters(props) {
  const GLOBAL = useContext(GlobalContext),
    { classes } = props;

  const [currentDates, setCurrentDates] = useState({
      start: GLOBAL.currentDates.get.start,
      end: GLOBAL.currentDates.get.end
    }),
    [checkedBrands, setCheckedBrands] = useState(GLOBAL.currentBrands.get),
    [checkedPlatforms, setCheckedPlatforms] = useState(GLOBAL.currentPlatforms.get),
    [focusedInput, setFocusedInput] = useState(null),
    [brandError, setBrandError] = useState(false),
    [platformError, setPlatformError] = useState(false),
    [boosted, setBoosted] = useState(false),
    [openBrands, setOpenBrands] = useState([]),
    [filterExcludeChildBrands, setFilterExcludeChildBrands] = useState([]);

  // on page load and filter change
  useEffect(() => {
    setCurrentDates({
      start: GLOBAL.currentDates.get.start,
      end: GLOBAL.currentDates.get.end
    });
    setCheckedBrands(GLOBAL.currentBrands.get);
    setCheckedPlatforms(GLOBAL.currentPlatforms.get);
    setFilterExcludeChildBrands(GLOBAL.excludeChildBrands.get);
  }, [
    GLOBAL.currentDates.get.start,
    GLOBAL.currentDates.get.end,
    GLOBAL.currentBrands.get,
    GLOBAL.currentPlatforms.get,
    GLOBAL.currentProjectId.get,
    GLOBAL.excludeChildBrands.get,
  ]);

  const handleUpdateDates = d => {
    let newDates = {
      start: d.startDate,
      end: d.endDate,
    };
    setCurrentDates(newDates);
  }

  const openBrand = b => {
    let newBrands;
    if (openBrands.indexOf(b) >= 0) {
      newBrands = [...openBrands.filter(f => f !== b)];
    } else {
      newBrands = [...openBrands, b];
    }
    setOpenBrands(newBrands)
  }

  const handleUpdateFocusedInput = f => setFocusedInput(f);

  const handleToggle = (v) => {
    let currentBrandIndex = checkedBrands.findIndex(f => f.brand === v.brand);
    //console.log('current brand index: ', currentBrandIndex);
    let currentBrand = checkedBrands[currentBrandIndex];
    //console.log('current brand: ', currentBrand);
    let updatedBrand = {
      ...currentBrand,
      active: (currentBrand.active) ? false : true
    };
    //console.log('updated brand', updatedBrand);
    let newBrands = [...checkedBrands];
    newBrands.splice(currentBrandIndex, 1, updatedBrand);
    //console.log('new brands', newBrands);
    //newBrands.push(updatedBrand);
    setCheckedBrands(newBrands);
  }

  const handleChildToggle = brand => {
    let currentChildBrands = [...filterExcludeChildBrands];
    let newChildBrands;
    if (currentChildBrands.indexOf(brand) >= 0) {
      newChildBrands = currentChildBrands.filter(f => f !== brand);
    } else {
      newChildBrands = [...currentChildBrands, brand];
    }
    setFilterExcludeChildBrands(newChildBrands);
  }

  const handleTogglePlatform = (v) => {
    let currentIndex = checkedPlatforms.indexOf(v);
    let newChecked = [...checkedPlatforms];
    if (currentIndex === -1) newChecked.push(v);
    else newChecked.splice(currentIndex, 1);
    setCheckedPlatforms(newChecked);
  }

  const handleBoostedChange = e => {
    setBoosted(e.target.checked)
  }

  const isCheckedPlatform = (v) => {
    if (checkedPlatforms.indexOf(v) !== -1) return true;
    else return false;
  }

  const pushUpdates = () => {
    let numCheckedBrands = checkedBrands.filter(f => f.active).length,
      numCheckedPlatforms = checkedPlatforms.length;
    if (numCheckedBrands === 0) {
      setBrandError(true);
    } else if (numCheckedPlatforms === 0) {
      setPlatformError(true);
    } else {
      GLOBAL.currentDates.set(currentDates);
      GLOBAL.currentBrands.set(checkedBrands);
      GLOBAL.currentPlatforms.set(checkedPlatforms);
      GLOBAL.boosted.set(boosted);
      GLOBAL.excludeChildBrands.set(filterExcludeChildBrands);
    }
  }

  let isOutsideRange;
  if (GLOBAL.currentDates.get.start) {
    isOutsideRange = day => (
      focusedInput === 'endDate' && day.isBefore(currentDates.start)
    );
  }

  return (
    <React.Fragment>
      {(brandError || platformError) &&
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={() => (brandError) ? brandError : platformError}
          autoHideDuration={6000}
          onClose={() => (brandError) ? setBrandError(false) : setPlatformError(false)}
          ContentProps={{
            'aria-describedby': 'uncheckError',
          }}
          message={<span id="uncheckError">You must select at least one {(brandError) ? 'brand' : 'platform'}.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() => (brandError) ? setBrandError(false) : setPlatformError(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      }
      <Box className={classes.titleSection}>
        <Typography className={classes.title}>
          Global Filters
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => pushUpdates()}
        >
          Update
        </Button>
      </Box>
      <Divider variant="middle" />
      <Typography className={classes.filterTitle}>
        Date Range <CustomTooltip type="small" helpText="You may currently only select a maximum of 14 days. In the coming weeks, we will permit any date range to be selected." />
      </Typography>
      <div className="datePicker">
        <DateRangePicker
          startDate={currentDates.start}
          startDateId="start"
          endDate={currentDates.end}
          endDateId="end"
          onDatesChange={range => handleUpdateDates(range)}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => handleUpdateFocusedInput(focusedInput)}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={1}
          small
        />
      </div>
      <Divider variant="middle" />
      <Box>
        <Typography className={classes.filterTitle}>
          Brands <CustomTooltip type="small" helpText="These are the brands in your current project. To switch to a different project, click Projects in the orange left bar." />
        </Typography>
        <List dense className={classes.list}>
          {checkedBrands.map(value => (
            <React.Fragment key={value.brand}>
              <ListItem
                button
                onClick={() => handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    color="secondary"
                    onChange={() => handleToggle(value)}
                    checked={value.active}
                  />
                </ListItemIcon>
                <ListItemText primary={`${value.brand}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => openBrand(value.brand)}
                  >
                    {openBrands.indexOf(value.brand) >= 0
                      ? 
                        <ExpandLess />
                      :
                        <ExpandMore />
                    }
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse
                in={openBrands.indexOf(value.brand) >= 0}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding dense>
                  {value.children.map(childBrand => (
                    <ListItem
                      button
                      onClick={() => handleChildToggle(childBrand)}
                      key={childBrand}
                      className={classes.childListItem}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          color="secondary"
                          onChange={() => handleChildToggle(childBrand)}
                          checked={filterExcludeChildBrands.indexOf(childBrand) < 0}
                        />
                      </ListItemIcon>
                      <ListItemText primary={`${childBrand}`} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Divider variant="middle" />
      <Box>
        <Typography className={classes.filterTitle}>
          Platforms
        </Typography>
        <List dense className={classes.list}>
          {['Facebook', 'Twitter', 'Instagram', 'YouTube'].map(value => (
            <ListItem key={value} button>
              <ListItemText primary={`${value}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  color="secondary"
                  onChange={() => handleTogglePlatform(value)}
                  checked={isCheckedPlatform(value)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider variant="middle" />
      <Box style={{paddingLeft: 18}}>
        <FormControlLabel
          control={
            <Switch
              checked={boosted}
              onChange={handleBoostedChange}
              name='boosted'
              color='secondary'
            />
          }
          label="Boosted Posts Only"
        />
      </Box>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    focusedInput: state.focusedInput,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateFocusedInput
  }
}

const styles = theme => ({
  title: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    float: 'left',
  },
  button: {
    float: 'right',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  titleSection: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  filterTitle: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
    fontSize: 12,
    textAlign: 'center',
  },
  tooltip: {
    lineHeight: '8px',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  childListItem: {
    paddingLeft: theme.spacing(4),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GlobalFilters));