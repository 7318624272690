import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { 
  Paper,
  Typography, 
  ButtonGroup,
  Button,
  Dialog,
  DialogContent
} from '@material-ui/core';
import Close from '@material-ui/icons/Close'

import CustomTooltip from '../CustomTooltip';
import PostExplorerModal from '../PostExplorerModal';
import GlobalContext from '../GlobalContext';
import ContentPerformanceTrends from '../components/graphs/ContentPerformanceTrends';
import LeaderboardTable from '../components/graphs/LeaderboardTable';

function OverviewPage(props) {
  const GLOBAL = useContext(GlobalContext),
    { classes } = props,
    [modal, setModal] = useState({
      open: false,
      brand: null,
      startDate: null,
      endDate: null,
    }),
    [timeScale, setTimeScale] = useState('weekly');

  const openModal = (brand, startDate, endDate) => {
    //console.log(brand, startDate, endDate);
    setModal({ ...modal, open: true, brand, startDate, endDate })
  }

  // update time scale automatically based on date
  useEffect(() => {
    let currentDates = GLOBAL.currentDates.get,
      start = moment.utc(currentDates.start),
      end = moment.utc(currentDates.end),
      numDays = end.diff(start, 'days') + 1;

    //console.log(start, end, numDays);

    let timeScale;
    if (numDays < 21) timeScale = 'daily';
    else if (numDays >= 21 && numDays < 90) timeScale = 'weekly';
    else timeScale = 'monthly';

    setTimeScale(timeScale);
  }, [GLOBAL.currentDates.get,
    GLOBAL.currentDates.get.start,
    GLOBAL.currentDates.get.end])

  return (
    <React.Fragment>
      <Dialog 
        open={modal.open}
        onClose={() => setModal({ ...modal, open: false })}
        maxWidth='xl'
        fullWidth
      >
        <DialogContent>
          <Close className={classes.closeButton} onClick={() => setModal({ ...modal, open: false })} />
          <PostExplorerModal
            brand={modal.brand}
            startDate={modal.startDate}
            endDate={modal.endDate}
          />
        </DialogContent>
      </Dialog>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Nichefire Leaderboard
          <CustomTooltip 
            helpText="Who is really winning? This leaderboard shows who is winning and losing in your dataset based on Nichefire Score." 
          />
        </Typography>
        <LeaderboardTable />
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Content Performance Trends
          <CustomTooltip 
            helpText="What are competitors doing? The top graph tracks Nichefire Scores. The bottom shows top-performing Content Types by brand and date. You can drill in to see actual posts right here." 
          />
        </Typography>
        <div className={classes.timeControls}>
          <ButtonGroup variant="contained" size="small">
            <Button onClick={() => setTimeScale('daily')} disabled={(timeScale === 'daily')}>Daily</Button>
            <Button onClick={() => setTimeScale('weekly')} disabled={(timeScale === 'weekly')}>Weekly</Button>
            <Button onClick={() => setTimeScale('monthly')} disabled={(timeScale === 'monthly')}>Monthly</Button>
          </ButtonGroup>
        </div>
        <ContentPerformanceTrends
          timeScale={timeScale}
          openModal={openModal}
        />
      </Paper>
    </React.Fragment>
  );
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    display: 'inline-block',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
  timeControls: {
    float: 'right',
  },
  insideTooltip: {
    fontSize: '14px',
  },
  formControl: {
    marginBottom: 20,
    minWidth: 120,
  },
});

export default withStyles(styles)(OverviewPage);