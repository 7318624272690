import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField
} from '@material-ui/core';
import axios from 'axios';

import GlobalContext from '../../GlobalContext';

function ProjectCreateModal(props) {
  const GLOBAL = useContext(GlobalContext),
    [projectName, setProjectName] = useState('');

  const handleChange = e => {
    setProjectName(e.target.value);
  }

  const handleSubmit = () => {
    axios.post(process.env.REACT_APP_SERVER_URL + '/project/add', { projectName })
      .then(res => {
        console.log(res.data.projectId);
        GLOBAL.projects.set([
          ...GLOBAL.projects.get,
          {
            _id: res.data.projectId,
            project_name: projectName
          } 
        ]);
        props.close();
      })
      .catch(err => console.log(err));
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
    >
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a project name to create a new project.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="projectName"
          label="Project Name"
          type="text"
          fullWidth
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = theme => ({
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
});

export default withStyles(styles)(ProjectCreateModal);