import React from 'react';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';
import {FacebookProvider, EmbeddedPost} from 'react-facebook';
import YouTube from 'react-youtube';

export default function PostEmbed(props) {
  const {platform, postUrl} = props;
  const originalPostId = props.postId;
  const postId = originalPostId.substring(3);

  if (platform === 'twitter') {
    return (
      <TwitterTweetEmbed 
        tweetId={postId}  
        options={{
          width: 400
        }}
      />
    )
  }

  else if (platform === 'instagram') {
    return (
      <InstagramEmbed
        url={postUrl}
        maxWidth={400}
      />
    )
  }

  else if (platform === 'facebook') {
    let fbPostUrl = `https://facebook.com/${postId.replace('_', '/posts/')}`;
    return (
      <FacebookProvider appId='122791775076798'>
          <EmbeddedPost
            href={fbPostUrl}
            width='400'
          />
      </FacebookProvider>
    )
  }

  else if (platform === 'youtube') {
    return (
      <YouTube
        videoId={postId}
        opts={{
          width: '400'
        }}
      />
    )
  }

  return `${platform} ${originalPostId} ${postId}`;
}