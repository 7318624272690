export const UPDATE_OVERVIEW_LEADERBOARD = 'UPDATE_OVERVIEW_LEADERBOARD';
export function updateOverviewLeaderboard(data) {
    return { type: UPDATE_OVERVIEW_LEADERBOARD, payload: { data } }
}

export const UPDATE_OVERVIEW_LINECHART = 'UPDATE_OVERVIEW_LINECHART';
export function updateOverviewLinechart(data) {
    return { type: UPDATE_OVERVIEW_LINECHART, payload: { data } }
}

export const UPDATE_OVERVIEW_HARPER = 'UPDATE_OVERVIEW_HARPER';
export function updateOverviewHarper(data) {
    return { type: UPDATE_OVERVIEW_HARPER, payload: { data } }
}

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal() {
    return { type: OPEN_MODAL }
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal() {
    return { type: CLOSE_MODAL }
}

export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export function updateModalContent(data) {
    return { type: UPDATE_CONTENT, payload: data }
}

export const UPDATE_GLOBAL_DATES = 'UPDATE_GLOBAL_DATES';
export function updateGlobalDates(data) {
    return { type: UPDATE_GLOBAL_DATES, payload: data }
}

export const UPDATE_FOCUSED_INPUT = 'UPDATE_FOCUSED_INPUT';
export function updateFocusedInput(data) {
    return { type: UPDATE_FOCUSED_INPUT, payload: data }
}

export const UPDATE_NETWORK_GRAPH = 'UPDATE_NETWORK_GRAPH';
export function updateNetworkGraph(data) {
    return { type: UPDATE_NETWORK_GRAPH, payload: data }
}

export const UPDATE_FEED_MAKEUP = 'UPDATE_FEED_MAKEUP';
export function updateFeedMakeup(data) {
    return { type: UPDATE_FEED_MAKEUP, payload: data }
}

export const UPDATE_PERFORMANCE_GRAPH = 'UPDATE_PERFORMANCE_GRAPH';
export function updatePerformanceGraph(data) {
    return { type: UPDATE_PERFORMANCE_GRAPH, payload: data }
}

export const UPDATE_RESPONSES_CIRCLES = 'UPDATE_RESPONSES_CIRCLES';
export function updateResponsesCircles(data) {
    return { type: UPDATE_RESPONSES_CIRCLES, payload: data }
}

export const UPDATE_RESPONSES_SENTIMENT = 'UPDATE_RESPONSES_SENTIMENT';
export function updateResponsesSentiment(data) {
    return { type: UPDATE_RESPONSES_SENTIMENT, payload: data }
}

export const UPDATE_RESPONSES_SPECTRUM = 'UPDATE_RESPONSES_SPECTRUM';
export function updateResponsesSpectrum(data) {
    return { type: UPDATE_RESPONSES_SPECTRUM, payload: data }
}

export const UPDATE_LOGGEDIN_USER = 'UPDATE_LOGGEDIN_USER';
export function updateLoggedInUser(data) {
    return { type: UPDATE_LOGGEDIN_USER, payload: data }
}

export const UPDATE_USER_SCOPE = 'UPDATE_USER_SCOPE';
export function updateUserScope(data) {
    return { type: UPDATE_USER_SCOPE, payload: data }
}

export const UPDATE_PROJECT_BRANDS = 'UPDATE_PROJECT_BRANDS';
export function updateProjectBrands(data) {
    return { type: UPDATE_PROJECT_BRANDS, payload: data }
}

export const REPLACE_PROJECT_BRANDS = 'REPLACE_PROJECT_BRANDS';
export function replaceProjectBrands(data) {
    return { type: REPLACE_PROJECT_BRANDS, payload: data }
}

export const UPDATE_ACTIVE_PLATFORMS = 'UPDATE_ACTIVE_PLATFORMS';
export function updateActivePlatforms(data) {
    return { type: UPDATE_ACTIVE_PLATFORMS, payload: data }
}

export const UPDATE_CUR_EDIT_PROJECT = 'UPDATE_CUR_EDIT_PROJECT';
export function updateCurrentEditProject(data) {
    return { type: UPDATE_CUR_EDIT_PROJECT, payload: data }
}

export const UPDATE_CUR_BRAND_DROP = 'UPDATE_CUR_BRAND_DROP';
export function updateCurrentBrandDrop(data) {
    return { type: UPDATE_CUR_BRAND_DROP, payload: data }
}

export const SET_AVAILABLE_BRANDS = 'SET_AVAILABLE_BRANDS';
export function setAvailableBrands(data) {
    return { type: SET_AVAILABLE_BRANDS, payload: data }
}

export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT';
export function updateCurrentProject(data) {
    return { type: UPDATE_CURRENT_PROJECT, payload: data }
}