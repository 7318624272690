import React, { useRef, useEffect, useState, useContext } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import {debounce} from 'lodash';
import { useLocation } from 'react-router';

import LoadingSpinner from '../ui/LoadingSpinner';
import GlobalContext from '../../GlobalContext';

function ContentCategorySentiment(props) {
  // configuration variables
  const GLOBAL = useContext(GlobalContext),
    [graphData, setGraphData] = useState([]),
    d3Container = useRef(null);
  
  const margin = {
    top: 25, 
    right: 25,
    bottom: 100, 
    left: 150
  }

  // establish min. width and fixed height
  //const width = 900 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;
  
  useEffect(() => {
    d3.select(d3Container.current).select('svg').remove();
    setGraphData([]);
    let queryOptions = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };

    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/content-category-sentiment', { queryOptions })
      .then(result => {
        //console.log(result.data)
        setGraphData(result.data)
      })
      .catch(err => console.log(err));
  }, [GLOBAL.currentDates.get.start, 
    GLOBAL.currentDates.get.end,
    GLOBAL.currentBrands.get,
    GLOBAL.currentPlatforms.get,
    GLOBAL.excludeChildBrands.get,
    GLOBAL.currentProjectId.get,
    GLOBAL.boosted.get,
  ])

  useEffect(() => {
    if (graphData.length > 0) drawChart();
  }, [graphData])

  const location = useLocation();

  // clean up event listeners on unmount
  useEffect(() => {
    return () => {
      d3.select(window).on(`resize.content_category_sentiment`, null);
    }
  }, [location])

  function drawChart() {
    const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;
    const initialWidth = getCurrentWidth();
    //console.log('parent node', d3.select(d3Container.current).node().parentNode.clientWidth);
    const svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', initialWidth + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit')

    // get axis values values
    const xValues = [...new Set(graphData.map(m => m.content_type))].sort();
    const yValues = [...new Set(graphData.map(m => m.brand))].sort();
    //console.log(xValues, yValues);

    const x = d3.scaleBand()
      .range([0, initialWidth])
      .domain(xValues)

    const y = d3.scaleBand()
      .range([height, 0])
      .domain(yValues)
      .padding(0.1)

    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    const z = d3.scaleSequential(d3.interpolateRdYlGn)
      .domain([-10, 10]);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
        .attr('transform', 'rotate(-45),translate(-10,-5)')
        .style('text-anchor', 'end')

    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis);
    
    //console.log(x.bandwidth(), y.bandwidth())

    svg.selectAll('rect')
      .data(graphData)
      .join('rect')
        .attr('width', (x.bandwidth() < y.bandwidth()) ? x.bandwidth() : y.bandwidth())
        .attr('height', (x.bandwidth() < y.bandwidth()) ? x.bandwidth() : y.bandwidth())
        .attr('x', d => x(d.content_type) + ((x.bandwidth() > y.bandwidth()) ? x.bandwidth()/2 - y.bandwidth()/2 : y.bandwidth()/2 - x.bandwidth()/2))
        .attr('y', d => y(d.brand))
        .attr('fill', d => z(d.avg_sentiment))
        .on('click', clickHandle)
        .on("mouseover", tooltipOpen)
        .on("mousemove", tooltipMove)
        .on("mouseout", tooltipClose);
    
    const resize = () => {
      //console.log('resize event triggered - content category sentiment');
      const update = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      update.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      update.select('.x.axis')
        .call(xAxis); 

      update.selectAll('rect')
        .attr('width', (x.bandwidth() < y.bandwidth()) ? x.bandwidth() : y.bandwidth())
        .attr('height', (x.bandwidth() < y.bandwidth()) ? x.bandwidth() : y.bandwidth())
        .attr('x', d => x(d.content_type) + ((x.bandwidth() > y.bandwidth()) ? x.bandwidth()/2 - y.bandwidth()/2 : y.bandwidth()/2 - x.bandwidth()/2))
        .attr('y', d => y(d.brand))
    }

    d3.select(window).on('resize.content_category_sentiment', debounce(resize, 300))
  }

  function clickHandle(d, i, n) {
    let currentBrand = d.brand;
    let contentType = d.content_type;
    props.openModal(currentBrand, GLOBAL.currentDates.get.start, GLOBAL.currentDates.get.end, contentType);
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = -tooltipHeight;

    const getCurrentFullWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth);
    const currentWidth = getCurrentFullWidth();
    
    //console.log(x, margin.left, offsetX, tooltipWidth);

    if (x + margin.left + offsetX + tooltipWidth > currentWidth) offsetX = -tooltipWidth - 15;
    //if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this);

    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Brand: </span>${d.brand}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Content Type: </span>${d.content_type}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Sentiment Score: </span>${d.avg_sentiment}</div>
              </div>`);

    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
        {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
        <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  )
}

export default ContentCategorySentiment;