import React, { useState, useEffect } from 'react';
import { Paper, Grid, Tabs, Tab, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PostSentimentSpectrum from '../graphs/PostSentimentSpectrum';
import PostPerformanceTimeline from '../graphs/PostPerformanceTimeline';
import CommentResonance from '../graphs/CommentResonance';
import SearchSentimentSpectrum from '../graphs/SearchSentimentSpectrum';
import PostEmbed from './PostEmbed';

const style = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  postRow: {
    padding: theme.spacing(1),
  },
  iframeContainer: {
    flexGrow: 2,
  },
  postExplorer: {
    flexShrink: 1,
    border: '2px solid #e5e5e5',
    borderRadius: '10px',
  },
  tabs: {
    marginBottom: theme.spacing(1),
  },
  tabContainer: {
    padding: theme.spacing(1),
    width: '100%',
    textAlign: 'center'
  },
  loadingDiv: {
    width: '100%',
    textAlign: 'center',
  },
  tag: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: '10px',
    margin: theme.spacing(1),
    display: 'inline-block',
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center'
  },
});

function SearchResults(props) {
  const { classes } = props;
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (props.tabOpen) setValue(props.tabOpen);
  }, [props.tabOpen]);
  let handleChange = (event, newValue) => setValue(newValue);
  let returnModalContent = (props.type === 'content') ? 
    props.results.data.map((d, i) => (
      <React.Fragment key={i}>
        <Paper className={classes.paper}>
            <Grid container spacing={1} key={d.url} className={classes.postRow}>
              <Grid item md={5} className={classes.iframeContainer}>
              <PostEmbed
                platform={d.platform}
                postId={d.post_id}
                postUrl={d.url}
              />
              </Grid>
              <Grid item md={7} className={classes.postExplorer}>
                <Tabs value={value} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange} className={classes.tabs} >
                  <Tab label="Post Overview" />
                  <Tab label="Conversation Sentiment" />
                  <Tab label="Conversation Topics" />
                </Tabs>
                {(value === 0) &&
                  <Box className={classes.tabContainer}>
                    <Box className={classes.tabContainer}>
                      <span style={{ fontWeight: 500 }}>Post Performance Score: </span>{d.score} <br />
                      {(d.avg_sentiment) &&
                        <div><span style={{ fontWeight: 500 }}>Avg. Sentiment Score: </span>{d.avg_sentiment} <br /></div>
                      }
                      <span style={{ fontWeight: 500 }}>Tags: </span>
                      <div className={classes.tag}>{d.content_type}</div>
                      {(d.content_appeal !== 'Uncategorized') &&
                        <div className={classes.tag}>{d.content_appeal}</div>
                      }
                      {(d.product_service !== 'Uncategorized') &&
                        <div className={classes.tag}>{d.product_service}</div>
                      }
                    </Box>
                    <Box className={classes.tabContainer}>
                      <Typography className={classes.graphTitle}>
                        Performance Over Time
                      </Typography>
                      <PostPerformanceTimeline postId={d.post_id} />
                    </Box>
                  </Box>
                } {(value === 1) &&
                  <Box className={classes.tabContainer}>
                    <Typography className={classes.graphTitle}>
                      Sentiment Spectrum
                    </Typography>
                    <PostSentimentSpectrum postId={d.post_id} />
                  </Box>
                } {(value === 2) &&
                  <Box className={classes.tabContainer}>
                    <Typography className={classes.graphTitle}>
                      Top Conversation Topics
                    </Typography>
                    <CommentResonance postId={d.post_id} />
                  </Box>
                }
              </Grid>
            </Grid>
        </Paper>
      </React.Fragment>
    ))
  : <SearchSentimentSpectrum
      data={props.results.data}
    />
  return returnModalContent;
}

export default withStyles(style)(SearchResults);