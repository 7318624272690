import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  filterBar: {
    padding: '8px',
    margin: '0 auto 16px',
    border: '3px solid #e5e5e5',
    borderRadius: '10px',
    width: '75%',
    height: '50px',
  },
  filterBarTitle: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'left',
    display: 'inline',
    marginRight: '30px',
    marginLeft: '11px',
    position: 'relative',
    bottom: '2px',
  },
  tagLabel: {
    fontSize: '16px',
    fontWeight: 500,
    display: 'inline',
    marginRight: '30px',
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center'
  },
  tag: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: '10px',
    margin: '16px',
    display: 'inline',
  },
});

let width = 900;
let height = 350;
let margin = {
  top: 25,
  bottom: 25,
  left: 50,
  right: 25,
};

function SearchSentimentSpectrum(props) {
  let d3Container = useRef(null);
  let [graphData, setGraphData] = useState();

  useEffect(() => {
    if (props.data.length > 0) {
      console.log('setting graph data...', props.data);
      setGraphData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (d3Container.current && graphData) {
      if (graphData.length > 0) {
        let svg = d3.select(d3Container.current)
          .append('g')
            .attr('transform', `translate(${margin.left},${margin.right})`);

        // draw x-axis
        let extent = d3.extent(graphData, d => new Date(d.date));
        let x = d3.scaleUtc()
          .domain(extent)
          .range([0, width]);
        let x_axis = d3.axisBottom(x)
          .tickFormat(d3.utcFormat("%b %d"));
        svg.append('g')
          .attr('class', 'x axis')
          .attr('transform', 'translate(0,' + (height / 2) + ')')
          .attr('fill', 'currentColor')
          .call(x_axis);

        // draw y-axis
        let y = d3.scaleLinear()
          .range([height, 0])
          .domain([-10, 10]);
        let y_axis = d3.axisLeft(y);
        svg.append('g')
          .attr('class', 'y axis')
          .attr('fill', 'currentColor')
          .call(y_axis);

        // draw x gridlines
        svg.append('g')
          .attr('class', 'grid')
          .call(d3.axisLeft(y)
            .tickSize(-(width))
            .tickFormat("")
          );
        svg.selectAll('.grid line')
          .style('stroke', '#e5e5e5');
        svg.select('.grid .domain')
          .attr('display', 'none');

        // define color scale
        let z = d3.scaleSequential(d3.interpolateRdYlGn)
          .domain([-10, 10]);

        // move labels to bottom
        svg.selectAll(".x .tick text")
          .attr('transform', `translate(0,${(height / 2)})`);

        // hide tick lines
        svg.selectAll(".x .tick line")
          .style('display', 'none');

        // draw data
        let g = svg.append('g');
        let dateGroups = g.selectAll('g')
          .data([...new Set(graphData.map(d => d.date))])
          .join('g');

        dateGroups.selectAll('circle')
          .data(date => {
            // only plot each unique date, sentiment score combination one time
            let scores = [...new Set(graphData.filter(d => date === d.date).map(d => d.sentiment_score))];
            let withDates = scores.map(d => { return { date: date, sentiment_score: d } });
            return withDates;
          })
          .join('circle')
          .attr("r", 5)
          .attr("cx", d => x(new Date(d.date)))
          .attr("cy", d => y(d.sentiment_score))
          .attr("fill", d => z(d.sentiment_score))
          .on('mouseover', tooltipOpen)
          .on('mousemove', tooltipMove)
          .on('mouseout', tooltipClose)
          //.on('click', d => {console.log(d.url); window.open(d.url, '_blank')});

        function getOffset(pos) {
          let x = pos[0],
            y = pos[1],
            offsetX = 80,
            offsetY = -210;
          if (x + offsetX + 300 > width + margin.left + margin.right) offsetX = -310;
          if (y + offsetY < 0) offsetY = 10;
          let offsetReturn = {
            x: offsetX,
            y: offsetY
          };
          return offsetReturn;
        }

        function tooltipOpen(d, i, n) {
          let pos = d3.mouse(this),
            offset = getOffset(pos),
            strDate = d.date,
            rawScore = d.sentiment_score,
            score = rawScore.toFixed(2),
            filteredComments = graphData.filter(f => f.date === strDate && f.sentiment_score === rawScore).map(m => `<li style="margin-left: 15px;">(${m.brand}, ${m.platform}) ${m.comment}</li>`),
            numComments = filteredComments.length;

          d3.select(d3Container.current)
            .append('foreignObject')
            .attr('id', 'tooltip')
            .attr('x', pos[0] + offset.x)
            .attr('y', pos[1] + offset.y)
            .attr('width', 1)
            .attr('height', 1)
            .style('overflow', 'visible')
            .html(`
              <body xmlns="http://www.w3.org/1999/xhtml">
                <div class="newTooltip">
                  <div class="tooltipLine"><span style="font-weight: bold">Score:</span> ${score}</div>
                  <div class="tooltipLine"><span style="font-weight: bold">${numComments} comment${(numComments > 1) ? 's' : ''}</span> on ${strDate}:</div>
                  <div style="width: 300px">${filteredComments}</div>
                </div>
              </body>
            `);
        }

        function tooltipMove() {
          let pos = d3.mouse(this);
          let offset = getOffset(pos);
          d3.select('#tooltip')
            .attr('x', pos[0] + offset.x)
            .attr('y', pos[1] + offset.y);
        }

        function tooltipClose() {
          d3.select('#tooltip').remove();
        }
      }
      else {
        let svg = d3.select(d3Container.current);
        let update = svg.append('g')
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        update.append('text')
          .text('Sentiment data is unavailable for this post.');
      }
    }
  }, [graphData]);

  return (
    <div style={{ textAlign: 'center' }}>
      <svg
        className="d3-component"
        id="spectrum"
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
        ref={d3Container}
      />
    </div>
  );

}

export default withStyles(styles)(SearchSentimentSpectrum);