import React, { useRef, useEffect, useState, useContext } from 'react';
import * as d3 from 'd3';
import legend from 'd3-svg-legend';
import axios from 'axios';
import moment from 'moment';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';

import GlobalContext from '../../GlobalContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import StrategySpotterBottom from './StrategySpotterBottom';

var margin = {
  top: 25,
  bottom: 25,
  left: 50,
  right: 200,
};
var width = 900 - margin.left - margin.right;
var height = 200 - margin.top - margin.bottom;

function StrategySpotter(props) {
  const GLOBAL = useContext(GlobalContext),
    [currentBrand, setCurrentBrand] = useState(),
    [graphData, setGraphData] = useState([]),
    [stackedGraphData, setStackedGraphData] = useState([]),
    [measurement, setMeasurement] = useState(),
    [dateKeys, setDateKeys] = useState([]),
    [timeScale, setTimeScale] = useState(),
    d3Container = useRef();

  const location = useLocation();

  const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;

  // clean up event listeners on unmount
  useEffect(() => {
    //console.log(location);
    return () => {
      d3.select(window).on(`resize.strategy_spotter`, null);
    }
  }, [location])

  // update graph filters
  useEffect(() => {
    setGraphData([]);
    setStackedGraphData([]);
    setDateKeys([]);
    setCurrentBrand(props.currentBrand);
    d3.select(d3Container.current).select('svg').remove();
  }, [props.currentBrand, 
      GLOBAL.currentDates.get.start, 
      GLOBAL.currentDates.get.end])
    
  useEffect(() => {
    setTimeScale(props.timeScale)
  }, [props.timeScale])

  // perform queries
  useEffect(() => {
    if (currentBrand) {
      d3.select(d3Container.current).select('svg').remove();
      setGraphData([]);
      let queryOptions = { 
        startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
        endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
        brands: [currentBrand],
        platforms: GLOBAL.currentPlatforms.get,
        boosted: GLOBAL.boosted.get,
        excludeChildBrands: GLOBAL.excludeChildBrands.get,
      };

      // currently returns date, post_type, content_type, product_service, score, total_engagements, avg_engagements, num_posts 
      axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/strategy-spotter', {queryOptions})
        .then(result => { setGraphData(result.data); } )
        .catch(err => console.log(err));

      // currently returns date, post_type, content_type, product_service, count
      axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/brand-content-by-day', {queryOptions})
        .then(result => { setStackedGraphData(result.data); })
        .catch(err => console.log(err));
    }
  }, [currentBrand, 
    GLOBAL.currentDates.get.start, 
    GLOBAL.currentDates.get.end,
    GLOBAL.currentPlatforms.get,
    GLOBAL.excludeChildBrands.get,
    GLOBAL.currentProjectId.get,
    GLOBAL.boosted.get,
  ])

  // update measurement
  useEffect(() => {
    setMeasurement(props.measurement);
  }, [props.measurement]);

  // draw empty line chart
  useEffect(() => {
    if (graphData.length > 0) {
      let svg = d3.select(d3Container.current)
        .append('svg')
          .attr('width', getCurrentWidth() + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
        .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('id', 'graphEdit');

      svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height})`) // move x-axis to bottom
        .attr('fill', 'currentColor')

      svg.append('g')
        .attr('class', 'y axis')
        .attr('fill', 'currentColor')

      svg.append('path')
        .attr('class', 'line')
        .style('stroke', '#28b9d1')

      svg.append('g')
        .attr('class', 'legend')
        .attr('id', 'ssLegend');

      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .attr("fill", "currentColor")
        .attr('id', 'ssLabel');
    }
  }, [graphData])

  // draw line chart (top graph)
  useEffect(() => {
    if (graphData.length > 0 && measurement && props.filters) {
      let {filters} = props,
        ctFilters = filters.contentTypes.filter(d => d.active).map(d => d.label),
        pcFilters = filters.productCategories.filter(d => d.active).map(d => d.label),
        ptFiltersRaw = filters.postTypes.filter(d => d.active).map(d => d.label),
        ptFilters = [];

      // break down post type filters
      ptFiltersRaw.forEach(f => {
        switch (f) {
          case 'Status':
            ptFilters.push('FB Status', 'TW Status', 'Retweet TW Status');
            break;
          case 'Link':
            ptFilters.push('FB Link', 'TW Link', 'Retweet TW Link', 'FB Event');
            break;
          case 'Photo':
            ptFilters.push('FB Photo', 'TW Photo', 'Retweet TW Photo', 'IN Photo', 'IN Image', 'IN Carousel_album');
            break;
          case 'Video':
            ptFilters.push('YT Video', 'FB Video', 'IN Video');
            break;
          default:
            ptFilters.push('FB Status', 'TW Status', 'Retweet TW Status');
        }
      })
      
      //console.log('filters:', show, ctFilters, pcFilters, ptFilters);

      // filter data by content type, product category, and post type
      let filteredData = graphData.filter(f => 
        ctFilters.indexOf(f.content_type) >= 0 &&
        pcFilters.indexOf(f.product_service) >= 0 &&
        ptFilters.indexOf(f.post_type) >= 0);

      //console.log('filtered data: ', filteredData);

      // date scaling function
      let scaler = date => {
        let parseDate = moment.utc(date).toDate();
        switch (timeScale) {
          case 'daily':
            return date;
          case 'weekly':
            return moment.utc(d3.utcWeek(parseDate)).format('YYYY-MM-DD');
          case 'monthly':
            return moment.utc(d3.utcMonth(parseDate)).format('YYYY-MM-DD');
          default:
            return date;
        }
      }

      let dateRange = d3.extent(graphData, d => moment.utc(scaler(d.date)).toDate());

      //console.log('scaled date range: ', dateRange);

      // set measurement field variable
      let measurementField;
      switch (measurement) {
        case 'Number of Posts':
          measurementField = 'num_posts';
          break;
        case 'Total Engagements':
          measurementField = 'total_engagements';
          break;
        case 'Avg. Engagements':
          measurementField = 'avg_engagements';
          break;
        case 'Nichefire Score':
          measurementField = 'nichefire_score';
          break;
        default:
          measurementField = 'num_posts';
      }

      // nest data by date
      let nestedData = d3.nest()
        .key(d => scaler(d.date))
        .rollup(d => {
          if (measurementField === 'num_posts') return d3.sum(d, m => m.num_posts)
          else if (measurementField === 'total_engagements') return d3.sum(d, m => m.total_engagements)
          else if (measurementField === 'avg_engagements') return d3.mean(d, m => m.avg_engagements)
          else if (measurementField === 'nichefire_score') return d3.mean(d, m => m.nichefire_score)
        })
        .entries(filteredData);

      //console.log('nested data: ', nestedData);

      setDateKeys(nestedData.map(m => m.key));
      drawLinechart(nestedData, measurement, dateRange, timeScale);
    }
  }, [graphData, measurement, props.filters, timeScale]);

  function drawLinechart(data, measurement, dateRange, timeScale) {
    //console.log('line chart: ', data, measurement, dateRange, timeScale);
    let svg = d3.select(d3Container.current)
      .select('#graphEdit');

    let numDays = d3.timeDay.count(dateRange[0], dateRange[1]) + 1;

    let tickScaler = () => {
      switch (timeScale) {
        case 'daily':
          return d3.utcDay.every(1 / numDays);
        case 'weekly':
          return d3.utcWeek.every(1);
        case 'monthly': 
          return d3.utcMonth.every(1);
        default: 
          return d3.utcWeek.every(1);
      }
    }

    // x-axis
    let x = d3.scaleUtc()
      .domain(dateRange)
      .range([0, getCurrentWidth()]);
    let x_axis = d3.axisBottom(x)
      .ticks(tickScaler())
      .tickFormat(d3.utcFormat("%b %d"));

    // y-axis
    let y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([height, 0]);
    let y_axis = d3.axisLeft(y)
      .ticks(6);

    // line function
    let line = d3.line()
      .x(d => x(moment.utc(d.key)))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    svg.selectAll('.dot')
      .data(data)
      .join('circle')
        .attr('class', 'dot')
        .attr('r', 5.5)
        .attr('fill', '#28b9d1')
        .on('click', clickHandle)
        .on("mouseover", tooltipOpen)
        .on("mousemove", tooltipMove)
        .on("mouseout", tooltipClose);

    // color scale
    let color = d3.scaleOrdinal()
      .domain([measurement])
      .range(['#28b9d1']);

    let colorLegend = legend.legendColor()
      .scale(color)
      .labelFormat(d3.format(".2f"))
      .shape('rect')
      .shapeHeight(2);

    // enter data
    let update = d3.select(d3Container.current)
      .transition()
      .duration(750)

    update.select('.line')
      .attr('d', line(data))

    update.select('.x.axis').call(x_axis);
    update.select('.y.axis').call(y_axis);
    update.select('#ssLabel').text(measurement)

    update.selectAll('.dot')
      .attr('cx', d => x(moment.utc(d.key)))
      .attr('cy', d => y(d.value))

    // draw legend
    let legendNode = svg.select("#ssLegend")
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', 'translate(' + (margin.left + getCurrentWidth() + 10) + ',' + (height / 2 - legendHeight / 2) + ')');

    const resize = () => {
      //console.log('resize triggered');
      const updateSvg = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      updateSvg.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      updateSvg.select('.x.axis')
        .call(x_axis); 
        
      updateSvg.select('.line')
        .attr('d', line(data))

      updateSvg.selectAll('.dot')
        .attr('cx', d => x(moment.utc(d.key)))

      updateSvg.select('.legend')
        .attr('transform', 'translate(' + (margin.left + getCurrentWidth() + 10) + ',' + (height / 2 - legendHeight / 2) + ')');
    }

    d3.select(window).on(`resize.strategy_spotter`, debounce(resize, 300))  
  }

  function clickHandle(d, i, n) { 
    //let timeParse = d3.utcFormat('%Y-%m-%d');
    let contentType = 'none';
    //let date = d.key ? d.key : 'none';
    let startDate,
      endDate;

    if (timeScale === 'daily') {
      startDate = moment.utc(d.key);
      endDate = moment.utc(d.key);
    } 
    else if (timeScale === 'weekly') {
      startDate = d3.utcWeek(moment.utc(d.key));
      endDate = moment.utc(startDate).add(6, 'days');
    }
    else if (timeScale === 'monthly') {
      startDate = d3.utcMonth(moment.utc(d.key));
      endDate = moment.utc(startDate).add(1, 'months');
    }  

    //console.log(currentBrand, contentType, date);
    props.openModal(currentBrand, startDate, endDate, contentType);
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let timeParse = d3.utcFormat("%b %d"),
      date = timeParse(moment.utc(d.key));
    let measurementVal = d.value;
    let pos = d3.mouse(this);

    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Date: </span>${date}</div>
                <div class="tooltipLine"><span style="font-weight: bold">${measurement}: </span>${measurementVal.toLocaleString()}</div>
              </div>`);

    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  return (
    <div>
      <div
        style={{height: height + margin.top + margin.bottom, position: 'relative'}}
      >
        {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
        <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
      </div>
      <StrategySpotterBottom
        graphData={stackedGraphData}
        filters={props.filters}
        dateKeys={dateKeys}
        currentBrand={currentBrand}
        timeScale={timeScale}
        openModal={props.openModal}
      />
    </div>
  )
}

export default StrategySpotter;