import React, { useState, useContext } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TextField, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography } from '@material-ui/core';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import SearchResults from '../components/ui/SearchResults';
import GlobalContext from '../GlobalContext';

const style = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    display: 'inline-block',
  },
});

function SearchPage(props) {
  const [searchTerm, updateSearchTerm] = useState('');
  const [resultType, updateResultType] = useState('content');
  const [searchResults, updateSearchResults] = useState(false);
  const GLOBAL = useContext(GlobalContext);
  const { classes } = props;
  const queryOptions = {
    searchTerm: searchTerm,
    startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
    endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
    platforms: GLOBAL.currentPlatforms.get,
    brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
    boosted: GLOBAL.boosted.get,
    excludeChildBrands: GLOBAL.excludeChildBrands.get,
  }
  const handleSubmit = (event) => {
    updateSearchResults('loading');
    if (resultType === 'content') {
      console.log(queryOptions);
      axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/search-posts', queryOptions)
        .then(result => { console.log(result); updateSearchResults(result) })
        .catch(err => console.log(err));
    }
    else {
      axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/search-conversations', queryOptions)
        .then(result => { console.log(result); updateSearchResults(result) })
        .catch(err => console.log(err));
    }
    event.preventDefault();
  }
  const handleChange = (event) => {
    updateSearchResults(false);
    if (event.target.id === 'searchTerm') updateSearchTerm(event.target.value);
    else if (event.target.name === 'resultType') updateResultType(event.target.value);
  }
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>Nichefire Search</Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="searchTerm"
            label="Search for..."
            name="searchTerm"
            autoComplete="searchTerm"
            onChange={handleChange}
            autoFocus
          />
          <FormControl
            component="fieldset"
            style={{ marginTop: 20 }}
          >
            <FormLabel component="legend">Show me...</FormLabel>
            <RadioGroup aria-label="resultType" name="resultType" value={resultType} onChange={handleChange} row>
              <FormControlLabel value="content" control={<Radio />} label="Content" />
              <FormControlLabel value="conversations" control={<Radio />} label="Conversations" />
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Search
          </Button>
        </form>
      </Paper>
      {(searchResults !== 'loading' && searchResults !== false)
        ? <SearchResults results={searchResults} type={resultType} />
        : (searchResults === 'loading') && <LoadingSpinner />
      }
    </React.Fragment>
  );
}

export default withStyles(style)(SearchPage);