import React, { useContext, useState, useEffect } from 'react';
import GlobalContext from '../GlobalContext';
import StrategyBenchmark from '../components/graphs/StrategyBenchmark';
import StrategySpotter from '../components/graphs/StrategySpotter';
import {
  makeStyles,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Popover,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ButtonGroup
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from 'react-modal';
import Close from '@material-ui/icons/Close'
import PostExplorerModal from '../PostExplorerModal';

export default function ComplexFilterContainer(props) {
  const GLOBAL = useContext(GlobalContext),
    classes = useStyles();

  const [options, setOptions] = useState({
    selectedBrand: GLOBAL.currentBrands.get.filter(f => f.active)[0].brand,
    measurement: 'Number of Posts',
    showMe: 'Content Type',
    contentTypes: contentTypesList(),
    productCategories: productCategoriesList(),
    postTypes: postTypesList(),
  });

  const [settings, setSettings] = useState({}),
    [timeScale, setTimeScale] = useState()

  const [popoverEl, setPopoverEl] = useState(null);

  const [modal, setModal] = useState({
    open: false,
    brand: null,
    startDate: null,
    endDate: null,
    contentType: null,
  })

  const { graph } = props;

  useEffect(() => {
    setSettings(Object.assign({}, options));
  }, [options]);

  useEffect(() => {
    const numDays = GLOBAL.currentDates.get.end.diff(GLOBAL.currentDates.get.start, 'days')+1;
    if (numDays < 21) setTimeScale('daily');
    else if (numDays >= 21 && numDays < 141) setTimeScale('weekly');
    else setTimeScale('monthly');
  }, [
    GLOBAL.currentDates.get.start,
    GLOBAL.currentDates.get.end
  ])

  //useEffect(() => console.log(settings), [settings])

  const handleChange = e => {
    e.preventDefault();
    setOptions({ ...options, [e.target.name]: e.target.value });
    if (e.target.name === 'measurement') setSettings({ ...settings, measurement: e.target.value });
  }

  const handleToggle = e => {
    let getAttr = attr => (e.target.getAttribute(attr))
      ? e.target.getAttribute(attr)
      : (e.target.offsetParent.getAttribute(attr))
        ? e.target.offsetParent.getAttribute(attr)
        : 'none';

    let filterType = getAttr('filtertype'),
      value = getAttr('id');

    let newArr = [...options[filterType]],
      index = newArr.findIndex(f => f.label === value),
      newObj = {
        label: value,
        active: (newArr[index].active ? false : true)
      };

    newArr.splice(index, 1, newObj);
    setOptions({ ...options, [filterType]: newArr })
  }

  const openModal = (brand, startDate, endDate, contentType) => {
    setModal({ ...modal, open: true, brand, startDate, endDate, contentType })
  }

  return (
    <Box style={{ display: 'inline' }}>
      <Modal isOpen={modal.open}
        onRequestClose={() => setModal({ ...modal, open: false })}
        style={customModalStyles}
      >
        <Close className={classes.closeButton} onClick={() => setModal({ ...modal, open: false })} />
        <PostExplorerModal
          brand={modal.brand}
          startDate={modal.startDate}
          endDate={modal.endDate}
          contentType={modal.contentType}
        />
      </Modal>
      {(graph === 'strategy-spotter') &&
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="selectedBrand">Brand</InputLabel>
        <Select
          value={options.selectedBrand}
          onChange={handleChange}
          inputProps={{
            name: 'selectedBrand',
            id: 'selectedBrand'
          }}
        >
          {GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand).map(opt => (
            <MenuItem key={opt} value={opt}>{opt}</MenuItem>
          ))}
        </Select>
      </FormControl>
      }
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="measurement">Measurement</InputLabel>
        <Select
          value={options.measurement}
          onChange={handleChange}
          inputProps={{
            name: 'measurement',
            id: 'measurement'
          }}
        >
          <MenuItem value={'Number of Posts'}>Number of Posts</MenuItem>
          <MenuItem value={'Total Engagements'}>Total Engagements</MenuItem>
          <MenuItem value={'Avg. Engagements'}>Average Engagements</MenuItem>
          <MenuItem value={'Nichefire Score'}>Nichefire Score</MenuItem>
        </Select>
      </FormControl>
      <Button
        aria-describedby='popover'
        color="secondary"
        variant="contained"
        onClick={e => setPopoverEl(e.currentTarget)}
        className={classes.filterButton}
      >
        Content Filters <ExpandMoreIcon />
      </Button>
      <FormControl 
        className={classes.formControl}
        style={{
          float: 'right',
          display: (graph === 'strategy-spotter') ? null : 'none'
        }}
      >
        <ButtonGroup variant="contained" size="small">
          <Button onClick={() => setTimeScale('daily')} disabled={(timeScale === 'daily')}>Daily</Button>
          <Button onClick={() => setTimeScale('weekly')} disabled={(timeScale === 'weekly')}>Weekly</Button>
          <Button onClick={() => setTimeScale('monthly')} disabled={(timeScale === 'monthly')}>Monthly</Button>
        </ButtonGroup>
      </FormControl>
      <Popover
        id='popover'
        open={Boolean(popoverEl)}
        anchorEl={popoverEl}
        onClose={() => setPopoverEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popoverContents}>
          <Button
            variant="contained"
            className={classes.popoverButton}
            onClick={() => setSettings(Object.assign({}, options))}
          >
            Update
          </Button>
          <Typography className={classes.popoverTitle}>Show me...</Typography>
          <FormControl style={{ marginBottom: 20 }}>
            <Select
              value={options.showMe}
              onChange={handleChange}
              inputProps={{
                name: 'showMe',
                id: 'showMe'
              }}
            >
              {['Content Type', 'Product Category', 'Post Type'].map(opt => (
                <MenuItem key={opt} value={opt}>{opt}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography className={classes.popoverTitle}>Filter by...</Typography>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ expanded: classes.filterSectionTitle }}
            >
              <Typography>Content Type</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.filterSection }}>
              <List dense className={classes.list}>
                {options.contentTypes.map(value => (
                  <ListItem
                    key={value.label}
                    filtertype="contentTypes"
                    id={value.label}
                    button
                    onClick={handleToggle}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        color="secondary"
                        onChange={handleToggle}
                        checked={value.active}
                        classes={{ root: classes.checkbox }}
                        filtertype="contentTypes"
                        id={value.label}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${value.label}`}
                      filtertype="contentTypes"
                      id={value.label}
                    />
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              classes={{ expanded: classes.filterSectionTitle }}
            >
              <Typography>Product Category</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.filterSection }}>
              <List dense className={classes.list}>
                {options.productCategories.map(value => (
                  <ListItem
                    key={value.label}
                    filtertype="productCategories"
                    id={value.label}
                    button
                    onClick={handleToggle}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        color="secondary"
                        filtertype="productCategories"
                        id={value.label}
                        onChange={handleToggle}
                        checked={value.active}
                        classes={{ root: classes.checkbox }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      filtertype="productCategories"
                      id={value.label}
                      primary={`${value.label}`}
                    />
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              classes={{ expanded: classes.filterSectionTitle }}
            >
              <Typography>Post Type</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.filterSection }}>
              <List dense className={classes.list}>
                {options.postTypes.map(value => (
                  <ListItem
                    key={value.label}
                    filtertype="postTypes"
                    id={value.label}
                    button
                    onClick={handleToggle}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        color="secondary"
                        filtertype="postTypes"
                        id={value.label}
                        onChange={handleToggle}
                        checked={value.active}
                        classes={{ root: classes.checkbox }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      filtertype="postTypes"
                      id={value.label}
                      primary={`${value.label}`}
                    />
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      </Popover>
      {(graph === 'strategy-benchmark') &&
        <StrategyBenchmark
          measurement={options.measurement}
          filters={settings}
          openModal={openModal}
        />
      }
      {(graph === 'strategy-spotter') &&
        <StrategySpotter
          measurement={options.measurement}
          currentBrand={options.selectedBrand}
          filters={settings}
          openModal={openModal}
          timeScale={timeScale}
        />
      }
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: '30px',
  },
  filterButton: {
    margin: theme.spacing(1),
    marginLeft: '30px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  popoverContents: {
    padding: theme.spacing(2),
  },
  popoverTitle: {
    fontSize: '0.9em',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(2),
  },
  popoverButton: {
    float: 'right',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  filterSectionTitle: {
    margin: '0 !important',
    minHeight: '32px !important',
  },
  filterSection: {
    padding: '0 10px !important'
  },
  list: {
    width: '100%',
  },
  checkbox: {
    padding: '2px !important',
  },
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
}))

const customModalStyles = {
  overlay: {
      backgroundColor: 'rgba(0,0,0,0.6)'
  },
  content: {
      top: '10%',
      left: '2.5%',
      bottom: '10%',
      right: '2.5%'
  },
}

const contentTypesList = () => {
  let types = [
    'Company pride',
    'Contests/sweepstakes',
    'Customer feature',
    'Educational',
    'Entertainment',
    'Events',
    'Locations',
    'Products/services',
    'Social responsibility',
    'User engagement',
    'Uncategorized'
  ];
  return types.map(type => ({ label: type, active: true }));
}

const productCategoriesList = () => {
  let types = [
    'Business banking',
    'Business credit cards',
    'Business financial planning',
    'Business investing',
    'Business loans/credit',
    'Credit cards',
    'Home financing',
    'Investing/wealth management',
    'Merchant services',
    'Online/mobile',
    'Personal banking',
    'Personal/auto loans',
    'Student loans'
  ];
  return types.map(type => ({ label: type, active: true }));
}

const postTypesList = () => {
  let types = [
    'Status', 'Link', 'Photo', 'Video'
  ];
  return types.map(type => ({ label: type, active: true }));
}