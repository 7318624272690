import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Delete } from '@material-ui/icons'
import axios from 'axios';

function mapStateToProps(state) {
    return {
        currentBrandDrop: state.currentBrandDrop,
        currentEditProject: state.currentEditProject,
    };
}

const Types = {
    BRANDBOX: 'brandbox',
}

const style = {
    height: '200px',
    width: '425px',
    marginBottom: '1.5rem',
    marginLeft: '4rem',
    color: 'black',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
    border: '2px black dashed',
}

const styles = theme => ({
    curBrandsTitle: {
        fontWeight: 500,
        marginBottom: 20
    },
    deleteIcon: {
        fontSize: '12px',
        color: '#9e1d21',
    }
})

class DropArea extends Component { 
    constructor(props) {
        super(props);
        this.deleteBrand = this.deleteBrand.bind(this);
        this.state = {
            currentBrands: [],
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.currentBrands !== prevProps.currentBrands) this.setState( { currentBrands: this.props.currentBrands } );
    }
    deleteBrand(event) {
        var oldBrands = [...this.state.currentBrands];
        var removeBrand = event.target.parentNode.id;
        var index = oldBrands.indexOf(removeBrand);
        oldBrands.splice(index, 1);
        this.setState({ currentBrands: oldBrands });
        axios.post(process.env.REACT_APP_SERVER_URL + '/project/remove-brand', {projectId: this.props.projectId, brand: removeBrand})
                    .catch(err => console.log(err));
    }
    render() {
        const { canDrop, isOver, connectDropTarget } = this.props;
        const isActive = canDrop && isOver
        let backgroundColor = '#f5f5f5'
        if (isActive) {
            backgroundColor = '#28B9D1'
        } else if (canDrop) {
            backgroundColor = '#e0e0e0'
        }
        const {classes} = this.props;
        const brandBoxStyle = {
            border: '1px dashed gray',
            backgroundColor: 'white',
            padding: '0.5rem 0.5rem',
            marginRight: '0.5rem',
            marginBottom: '0.5rem',
            float: 'left',
        }
        return (
            <div
                ref={connectDropTarget}
                style={Object.assign({}, style, { backgroundColor })}
            >
                <Typography className={classes.curBrandsTitle}>Current Brands</Typography>
                {
                    this.state.currentBrands.map(brand => (
                        <div key={brand} style={brandBoxStyle} >{brand} <Delete className={classes.deleteIcon} id={brand} onClick={this.deleteBrand} /></div>
                    ))
                }
            </div>
        )
    }
}

let DropComponent = DropTarget(
    Types.BRANDBOX,
    {
        drop: () => ({ name: 'Dustbin' }),
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(DropArea);
DropComponent = withStyles(styles)(DropComponent);
DropComponent = connect(mapStateToProps)(DropComponent);
export default DropComponent;