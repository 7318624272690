import React from 'react';
import Modal from 'react-modal';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.8)'
  },
  content: {
    top: '35%',
    left: '30%',
    bottom: '35%',
    right: '30%',
    textAlign: 'center'
  },
}

const styles = theme => ({
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
});

Modal.setAppElement("#root");

function UpgradeModal(props) {
  const { classes } = props;
  return (
    <Modal style={customModalStyles}
      isOpen={props.modalOpen}
      onRequestClose={() => props.closeModal()}
    >
      <span style={{ textAlign: 'center' }}>This feature is <strong>not available</strong> for demo accounts.<br />
        <br />
            Want to upgrade your account? Get in touch.<br />
        <br /></span>
      <Button
        variant="contained"
        className={classes.button}
        href="mailto:kelamin@nichefire.com"
      >
        Contact Us
      </Button>
    </Modal>
  )
}

export default withStyles(styles)(UpgradeModal);