import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import GlobalContext from '../../GlobalContext';
import axios from 'axios';
import LoadingSpinner from '../ui/LoadingSpinner';

export default function LeaderboardTable(props) {
  const GLOBAL = useContext(GlobalContext),
    [graphData, setGraphData] = useState([]);

  // on page load and filter change
  useEffect(() => {
    setGraphData([]);
    let options = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };
    
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/leaderboard-data', { queryOptions: options })
      .then(res => {
        //console.log('leaderboard: ', res.data);
        setGraphData(res.data);
      });
  }, [GLOBAL.currentDates.get.start, 
    GLOBAL.currentDates.get.end, 
    GLOBAL.currentBrands.get, 
    GLOBAL.currentPlatforms.get, 
    GLOBAL.currentProjectId.get,
    GLOBAL.boosted.get,
    GLOBAL.excludeChildBrands.get]
  );

  const leaderboardOptions = {
    columns: [
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "nichefire_score",
        label: "Nichefire Score",
        options: {
          filter: false,
          sort: true,
          sortDirection: 'desc',
          hint: 'The Nichefire Score measures the quality of engagements brands receive. We take into account sentiment, engagement types, activity and more.',
        }
      },
      {
        name: "post_count",
        label: "Post Count",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "engagements",
        label: "Total Engagements",
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return value.toLocaleString()
          }
        }
      },
      {
        name: "sentiment_score",
        label: "Sentiment Score",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: 'fan_count',
        label: 'Fan Count',
        options: {
          filter: false,
          sort: true,
          customBodyRender: value => {
            return value.toLocaleString()
          }
        }
      }
    ],
    options: {
      pagination: false,
      selectableRows: 'none',
      search: false,
      filter: false,
      print: true,
      download: true,
      viewColumns: false,
      elevation: 0,
    }
  };

  return (graphData.length > 0)
    ?
    <div id="LeaderboardTable">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={graphData}
          columns={leaderboardOptions.columns}
          options={leaderboardOptions.options}
        />
      </MuiThemeProvider>
    </div>
    : <LoadingSpinner />
}

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: 'rgba(245, 127, 32, 0.2)',
        },
      }
    },
    MuiTooltip: {
      tooltip: {
          fontSize: '14px'
      }
    }
  },
})