import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import OverviewPage from './pages/OverviewPage';
import StrategiesPage from './pages/StrategiesPage';
import EngagementsPage from './pages/EngagementsPage';
import SearchPage from './pages/SearchPage';
import Login from './Login';
import GlobalFilters from './GlobalFilters';
import TopBar from './TopBar';
import UpgradeModal from './components/ui/UpgradeModal';
import GlobalContext from './GlobalContext';
import SideDrawer from './views/SideDrawer';
import LoadingSpinner from './components/ui/LoadingSpinner';
import * as fn from './functions';
import { setAvailableBrands } from './redux/actions/index'

axios.defaults.withCredentials = true;

function mapStateToProps(state) {
  return {
    userType: state.userType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setAvailableBrands,
  }
}

const drawerWidth = 300;

const App = props => {
  const GLOBAL = useContext(GlobalContext),
    { classes } = props,
    [state, setState] = useState({
      modalOpen: false,
    }),
    [filterOpen, setFilterOpen] = useState(false),
    [noProjects, setNoProjects] = useState(false);

  // on initial load only
  useEffect(() => {
    //console.log('app.js trigger', GLOBAL.user.get);
    // if user login prop is set
    if (GLOBAL.user.get) {
      //setUser(GLOBAL.user.get);
      // get user projects
      axios.get(process.env.REACT_APP_SERVER_URL + '/project/list')
        .then(
          res => {
            //console.log('my projects...', res.data);
            GLOBAL.projects.set(res.data || []);
            // get brands in first project
            if (res.data.length > 0) {
                axios.post(process.env.REACT_APP_SERVER_URL + '/project/get-brands', { projectId: res.data[0]._id })
                .then(
                  brands => {
                    //console.log('current project brands...', brands.data);
                    GLOBAL.currentProjectId.set(res.data[0]._id);
                    GLOBAL.currentBrands.set(fn.establishBrands(brands.data));
                  }
                ).catch(err => console.log(err));
            }
            // if user has no projects
            else {
              setNoProjects(true);
            }
          }
        ).catch(err => console.log(err));

      axios.get(process.env.REACT_APP_SERVER_URL + '/bigquery/get-all-brands')
        .then(res => {
          props.dispatch(props.setAvailableBrands(res.data)); 
          //console.log('available brands:', res.data);
        })
        .catch(err => console.log(err));
    }

    // if user login prop is not set, see if there's a session in memory
    else {
      axios.get(process.env.REACT_APP_SERVER_URL + '/auth/get-user-session')
        .then(res => {
          if (res.data.username) {
            GLOBAL.user.set(res.data);
          }
        })
        .catch(err => console.log(err));
    }
  }, [GLOBAL.user.get]);

  // demo account functionality
  /* useEffect(() => {
  if ( props.userType === 'demo' && GLOBAL.currentProject.get.id === 'none' ) {
    console.log('Updating...');
    props.dispatch(props.updateCurrentProject('5dc78692e16b884194064481'));
    let demoBrands = ['Ally', 'Bank of America', 'Citi'];
    props.dispatch(props.replaceProject//Brands(demoBrands));
  }
  }, [props.userType, props.currentProject]); */

  // if user is not logged in
  if (!GLOBAL.user.get) {
    return (
      <div className="login-container" id="AppContainer">
        <TopBar showNav={false} />
        <Route component={Login} />
      </div>
    )
  }

  // if user is logged in
  else {
    //console.log('current project: ', GLOBAL.currentProjectId.get);
    // if project brands are populated
    if (GLOBAL.currentBrands.get.length > 0 || noProjects) {
      return (
        <React.Fragment>
          <div className={classes.root} id="AppContainer">
            <UpgradeModal modalOpen={state.showModal} closeModal={() => setState({ showModal: false })} />
            <CssBaseline />
            <TopBar 
              showNav={true} 
              currentPage={props.location.pathname} 
              openFilter={() => setFilterOpen(true)}
              closeFilter={() => setFilterOpen(false)}
              filterStatus={filterOpen}
            />
            <SideDrawer
              showModal={() => setState({ modalOpen: true })}
            />
            <main className={classes.content}>
              {(!noProjects) && 
              <Container maxWidth={false}>
                <div className={classes.toolbar} />
                <Switch>
                  <Route path="/overview" component={OverviewPage} />
                  <Route path="/tactics" component={StrategiesPage} />
                  <Route path="/engagements" component={EngagementsPage} />
                  <Route path="/search" component={SearchPage} />
                  <Route component={OverviewPage} />
                  <Redirect from="/" to="/overview" />
                </Switch>
              </Container>
              }
            </main>
            {(GLOBAL.currentBrands.get !== 'none' && GLOBAL.currentBrands.get !== null && GLOBAL.currentBrands.get !== undefined) &&
              <Drawer
                className={filterOpen ? classes.drawer : null}
                variant="persistent"
                classes={{ paper: classes.drawerPaper }}
                anchor="right"
                open={filterOpen}
              >
                <div className={classes.toolbar} />
                <GlobalFilters />
              </Drawer>
            }
          </div>
        </React.Fragment>
      );
    }

    // if project page isn't set, show loading spinner
    else {
      return (<LoadingSpinner />);
    }
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: 'hidden',
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(App)));