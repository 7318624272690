import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper
} from '@material-ui/core';
import Modal from 'react-modal';
import Close from '@material-ui/icons/Close'

import CustomTooltip from '../CustomTooltip';
import ComplexFilterContainer from '../views/ComplexFilterContainer';
import ContentCategorySentiment from '../components/graphs/ContentCategorySentiment';
import PostExplorerModal from '../PostExplorerModal';

function StrategiesPage(props) {
  //const GLOBAL = useContext(GlobalContext),
  const {classes} = props;

  const [modal, setModal] = useState({
    open: false,
    brand: null,
    startDate: null,
    endDate: null,
    contentType: null,
  })

  const openModal = (brand, startDate, endDate, contentType) => {
    setModal({ ...modal, open: true, brand, startDate, endDate, contentType })
  }

  return (
    <React.Fragment>
      <Modal isOpen={modal.open}
        onRequestClose={() => setModal({ ...modal, open: false })}
        style={customModalStyles}
      >
        <Close className={classes.closeButton} onClick={() => setModal({ ...modal, open: false })} />
        <PostExplorerModal
          brand={modal.brand}
          startDate={modal.startDate}
          endDate={modal.endDate}
          contentType={modal.contentType}
        />
      </Modal>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Strategy Benchmark
          <CustomTooltip helpText="See how your competitors stack up. Click a bar to learn more." />
        </Typography>
        <ComplexFilterContainer
          graph='strategy-benchmark'
        />
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
            Strategy Spotter
            <CustomTooltip helpText="Explore competitor performance by exploring this graph's robust controls and filters. Click a data point in the top graph or bar in the bottom graph to see more." />
        </Typography>
        <ComplexFilterContainer
          graph='strategy-spotter'
        />
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Content Category Sentiment
          <CustomTooltip helpText="If a square is missing on the heatmap, the brand did not have any posts in the content category per your current filters." />
        </Typography>
        <ContentCategorySentiment 
          openModal={openModal}
        />
      </Paper>
    </React.Fragment>
  );
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    display: 'inline',
    verticalAlign: 'top',
  },
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
  insideTooltip: {
    fontSize: '14px',
  },
});

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  content: {
    top: '10%',
    left: '2.5%',
    bottom: '10%',
    right: '2.5%'
  },
}

export default withStyles(styles)(StrategiesPage);