import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { connect } from 'react-redux';
import axios from 'axios';

import { updateCurrentEditProject, updateCurrentBrandDrop } from '../redux/actions/index';

function mapStateToProps(state) {
  return {
    currentEditProject: state.currentEditProject,
    currentBrandDrop: state.currentBrandDrop,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateCurrentEditProject,
    updateCurrentBrandDrop,
  }
}

const Types = {
  BRANDBOX: 'brandbox',
}

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 0.5rem',
  marginRight: '0.5rem',
  marginBottom: '0.5rem',
  cursor: 'move',
  float: 'left',
}

class BrandBox extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { name, isDragging, connectDragSource } = this.props;
    const opacity = isDragging ? 0.4 : 1
    return (
      <div ref={connectDragSource} style={Object.assign({}, style, { opacity })}>
        {name}
      </div>
    )
  }
}

BrandBox = DragSource(
  Types.BRANDBOX,
  {
    beginDrag: props => ({ name: props.name }),
    endDrag(props, monitor) {
      const item = monitor.getItem()
      const dropResult = monitor.getDropResult()
      if (dropResult) {
        console.log('project id: ', props.projectId);
        console.log('brand: ', item.name);
        props.dispatch(props.updateCurrentBrandDrop(item.name));
        axios.post(process.env.REACT_APP_SERVER_URL + '/project/add-brand',
          {
            projectId: props.projectId,
            brand: item.name
          }).then(() => {
            
          }).catch(
            err => console.log(err)
          );
      }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(BrandBox);

export default connect(mapStateToProps, mapDispatchToProps)(BrandBox);