import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    top: '4px',
    fontSize: '16px',
  },
  insideTooltip: {
    fontSize: '14px',
  },
  smallIcon: {
    position: 'relative',
    top: '3px',
    fill: 'rgba(0,0,0,.87)',
    fontSize: '16px',
  },
  smallTooltip: {
    fontSize: '14px',
  }
})

function CustomTooltip(props) {
  const { classes } = props;
  return (
    <Tooltip title={<React.Fragment>
      <Typography className={(props.type === 'small') ? classes.smallTooltip : classes.insideTooltip}>{props.helpText}</Typography>
    </React.Fragment>}
      placement="right-start">
      <HelpOutline className={(props.type === 'small') ? classes.smallIcon : classes.icon} />
    </Tooltip>
  );
}

export default withStyles(styles)(CustomTooltip);