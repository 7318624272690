import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import legend from 'd3-svg-legend';

import LoadingSpinner from '../ui/LoadingSpinner';

export default function CommentResonance(props) {
  // configuration variables
  const [graphData, setGraphData] = useState([]),
    d3Container = useRef()

  let margin = {
    top: 25,
    bottom: 25,
    left: 25,
    right: 100,
  };

  let width = 600 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom

  useEffect(() => {
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/get-comment-resonance', { postId: props.postId })
      .then(result => { setGraphData(result.data); })
      .catch(err => console.log(err));
  }, [props.postId]);

  useEffect(() => {
    if (d3Container.current && graphData) {
      if (graphData.length > 0) {
        drawGraph();
      }
    }
  }, [graphData]);

  const drawGraph = () => {
    //console.log('conversation topics data:', graphData);
    let svg = d3.select(d3Container.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
    .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr('id', 'graphEdit')
      
    let pack = data => d3.pack()
      .size([width - 2, height - 2])
      .padding(3)(d3.hierarchy({ children: data })
        .sum(d => d.count_resonance));
    
    let color = d3.scaleOrdinal(graphData.map(d => d.gen_resonance), d3.schemeCategory10);
    let root = pack(graphData);

    let leaf = svg.selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', d => `translate(${d.x + 1},${d.y + 1})`);
      
    leaf.append('circle')
      .attr('r', d => d.r)
      .attr('fill', d => color(d.data.gen_resonance))
      .style('cursor', 'pointer')
      .on('mouseover', tooltipOpen)
      .on('mousemove', tooltipMove)
      .on('mouseout', tooltipClose)
      .on('click', clickHandle);

    // draw legend
    svg.append('g')
      .attr('class', 'legend')
      .attr('id', 'crLegend');
    let colorLegend = legend.legendColor()
      .scale(color)
      .labelFormat(d3.format(".2f"));
    let legendNode = svg.select(".legend")
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', 'translate(' + (width + 10) + ',' + ((height / 2) - (legendHeight / 2)) + ')')
  }

  return (
    <div style={{ textAlign: 'center' }} >
      <div>
        {(graphData.length === 0) && <div style={{ margin: '20px' }}><LoadingSpinner /></div>}
        <div ref={d3Container} />
      </div>
    </div>
  );

  /* function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = tooltipWidth + 30,
      offsetY = tooltipHeight * 2 + 60;

    //console.log(x + margin.left + offsetX + tooltipWidth);
    //console.log(tooltipWidth)
    //console.log(width + margin.left + margin.right);
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -(tooltipWidth/2);
    if (y + margin.top + offsetY + tooltipHeight < tooltipHeight + 15) offsetY = tooltipHeight * 3 + 80;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  } */

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(d3Container.current);
    let info = d.data.gen_resonance;
    let postNum = d.value;
    //console.log(pos, info, postNum);
    
    d3.select(d3Container.current)
      .select('svg')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .attr('x', pos[0] - 35)
        .attr('y', pos[1] - 75)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Topic: </span>${info}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Comments: </span>${postNum}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Avg. Sentiment Score: </span>${d.data.sentiment_score}</div>
              </div>`
        );
  }

  function tooltipMove() {
    let pos = d3.mouse(d3Container.current);
    /* let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions); */

    d3.select('#tooltip')
      .attr('x', pos[0] - 35)
      .attr('y', pos[1] - 75)
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  function clickHandle(d) {
    props.openSentimentSpectrum(d.data.gen_resonance);
  }
}