import React, { useRef, useEffect, useState, useContext } from 'react';
import * as d3 from 'd3';
import legend from 'd3-svg-legend';
import moment from 'moment';
import axios from 'axios';
import {debounce} from 'lodash';
import { useLocation } from 'react-router';

import { getBrandScale } from '../../themes/CommonColors';
import GlobalContext from '../../GlobalContext';
import LoadingSpinner from '../ui/LoadingSpinner';

export default function ContentPerformanceTrends(props) {
  // configuration variables
  const GLOBAL = useContext(GlobalContext),
    [graphData, setGraphData] = useState([]),
    d3Container = useRef(null)
  
  const margin = {
    top: 25, 
    right: 170,
    bottom: 25, 
    left: 50
  }

  // establish min. width and fixed height
  const width = 900 - margin.left - margin.right;
  const height = 350 - margin.top - margin.bottom;

  // on page load and filter change
  useEffect(() => {
    setGraphData([]);
    d3.select(d3Container.current).select('svg').remove();
    let options = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/recent-nf-score', { queryOptions: options })
      .then(res => {
        //console.log('raw data:', res.data);
        setGraphData(res.data);
      });
  }, [GLOBAL.currentDates.get.start,
      GLOBAL.currentDates.get.end,
      GLOBAL.currentBrands.get,
      GLOBAL.currentPlatforms.get,
      GLOBAL.boosted.get,
      GLOBAL.excludeChildBrands.get]
  );

  // update chart upon data update
  useEffect(() => {
    if (graphData.length > 0) {
      startChart(graphData);
    }
  }, [graphData]);

  // prepare data and draw chart
  useEffect(() => {
    if (graphData.length > 0) {
      populateChart(graphData, props.timeScale);
    }
  }, [props.timeScale])

  // clean up event listeners on unmount
  const location = useLocation();
  useEffect(() => {
    return () => {
      d3.select(window).on(`resize.content_performance_trends`, null);
    }
  }, [location]);

  const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;

  // draw chart framework
  const startChart = (rawData) => {
    // draw SVG
    let svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', getCurrentWidth() + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit');

    // draw axes
    svg.append('g')
      .attr('class', 'x axis')
      .attr('fill', 'currentColor')
      .attr('transform', `translate(0,${height})`); // move x-axis to bottom

    svg.append('g')
      .attr('class', 'y axis')
      .attr('fill', 'currentColor');

    // draw line containers
    let brands = Array.from(new Set(rawData.map(d => d.brand)));
    let lines = svg.selectAll('.line')
      .data(brands)
      .join('g')
        .attr('class', 'line')
        .attr('id', d => d);

    lines.append('path')
      .attr('class', 'linePath')

    // draw legend
    svg.append('g')
      .attr('class', 'legend')
      .style('font-size', '12px')

    // y-axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr('dy', '1em')
      .style("text-anchor", "middle")
      .attr("fill", "currentColor")
      .text("Nichefire Score");

    populateChart(rawData, props.timeScale);
  }

  // add data to chart
  function populateChart(rawData, timeScale) {
    // prepare data
    //let brands = Array.from(new Set(rawData.map(d => d.brand)));
    let scaler = (date) => {
      let parseDate = moment.utc(date).toDate();
      switch (timeScale) {
        case 'daily':
          return date;
        case 'weekly':
          return moment.utc(d3.utcWeek(parseDate)).format('YYYY-MM-DD');
        case 'monthly':
          return moment.utc(d3.utcMonth(parseDate)).format('YYYY-MM-DD');
        default:
          return date;
      }
    }

    let nestedData = d3.nest()
      .key(d => d.brand)
      .key(d => scaler(d.date))
      .rollup(d => d3.mean(d, m => m.score))
      .entries(rawData);

    let scoreValues = [].concat.apply([], nestedData.map(n => n.values.map(val => val.value)));

    let scoreMax = d3.max(scoreValues) + 5,
      dateRange = d3.extent(rawData, d => moment.utc(scaler(d.date)).toDate()),
      numDays = d3.timeDay.count(rawData[0], dateRange[1]) + 1;

    let tickScaler = () => {
      switch (timeScale) {
        case 'daily':
          return d3.utcDay.every(1 / numDays);
        case 'weekly':
          return d3.utcWeek.every(1);
        case 'monthly': 
          return d3.utcMonth.every(1);
        default:
          return d3.utcDay.every(1);    
      }
    }

    // graph functions
    let x = d3.scaleUtc()
      .domain(dateRange)
      .range([0, getCurrentWidth()]);
    let xAxis = d3.axisBottom(x)
      .ticks(tickScaler())
      .tickFormat(d3.utcFormat('%b %d'));

    let y = d3.scaleLinear()
      .domain([0, scoreMax])
      .range([height, 0]);
    let yAxis = d3.axisLeft(y);

    let lineGenerator = d3.line()
      .x(d => x(moment.utc(d.key)))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    let svg = d3.select(d3Container.current)
      .select('#graphEdit')
      .transition()
        .duration(750);

    // populate axes
    svg.select('.x.axis').call(xAxis);
    svg.select('.y.axis').call(yAxis);

    // draw lines
    let lineData = d3.select(d3Container.current)
      .select('#graphEdit')
      .selectAll('.line')
      .data(nestedData);

    svg.selectAll('.line')
      .selectAll('.linePath')
        .attr('d', d => lineGenerator(nestedData.filter(f => d === f.key)[0].values))
        .style('stroke', (d, i, n) => brandColor(n[i].parentNode.id));;

    // draw dots
    lineData.selectAll('.dot')
      .data(d => d.values)
      .join('circle')
        .attr('fill', (d, i, n) => brandColor(n[i].parentNode.id))
        .attr('r', 5)
        .attr('class', 'dot')
        .style('stroke-width', 1)
        .on('mouseover', tooltipOpen)
        .on('mousemove', tooltipMove)
        .on('mouseout', tooltipClose)
        .on('click', (d, i, n) => clickHandle(d, i, n));

    svg.selectAll('.dot')
      .attr('cx', d => x(moment.utc(d.key)))
      .attr('cy', d => y(d.value))

    // draw legend
    let colorLegend = legend.legendColor()
      .scale(getBrandScale(GLOBAL.currentBrands.get.filter(b => b.active === true)))
      .labelFormat(d3.format('.2f'));
    let legendNode = d3.select(d3Container.current)
      .select('.legend')
      .style('overflow', 'visible')
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', `translate(${getCurrentWidth() + 25},${height / 2 - legendHeight / 2})`);

    // adjustments on window resize
    const resize = () => {
      const update = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      update.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      update.select('.x.axis')
        .call(xAxis); 

      update.selectAll('.line')
        .selectAll('.linePath')
          .attr('d', d => lineGenerator(nestedData.filter(f => d === f.key)[0].values))

      update.selectAll('.dot')
        .attr('cx', d => x(moment.utc(d.key)))
        .attr('cy', d => y(d.value))

      update.select('.legend')
        .attr('transform', `translate(${getCurrentWidth() + 25},${height / 2 - legendHeight / 2})`);
    }

    d3.select(window).on(`resize.content_performance_trends`, debounce(resize, 300))
  }

  // color function
  let brandColor = brand => GLOBAL.currentBrands.get.filter(b => (b.brand === brand))[0].color;

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this),
      brand = n[i].parentNode.id,
      timeParse = d3.utcFormat('%b %d'),
      date = timeParse(moment(d.key)),
      sourceData,
      score,
      topContentType,
      sentiment,
      numPosts,
      numEngagements,
      avgEngagements;

    if (props.timeScale === 'daily') {
      sourceData = graphData.find(f => f.brand === brand && f.date === d.key);
      score = sourceData.score.toFixed(2);
      topContentType = sourceData.top_content_type;
      sentiment = sourceData.avg_sentiment;
      numPosts = sourceData.num_posts;
      numEngagements = sourceData.num_engagements;
      avgEngagements = (numEngagements/numPosts).toFixed(2);
    }

    else if (props.timeScale === 'weekly') {
      //console.log('graph data:', graphData);
      sourceData = graphData.filter(f => f.brand === brand && d.key === moment.utc(d3.utcWeek(moment.utc(f.date).toDate())).format('YYYY-MM-DD'));
      //console.log('source data:', sourceData);
      score = d3.mean(sourceData, d => d.score).toFixed(2);
      topContentType = sourceData[0].top_content_type;
      sentiment = d3.mean(sourceData, d => d.avg_sentiment).toFixed(2);
      numPosts = d3.sum(sourceData, d => d.num_posts);
      numEngagements = d3.sum(sourceData, d => d.num_engagements);
      avgEngagements = (numEngagements/numPosts).toFixed(2);
    }

    else if (props.timeScale === 'monthly') {
      sourceData = graphData.filter(f => f.brand === brand && d.key === moment.utc(d3.utcMonth(moment.utc(f.date).toDate())).format('YYYY-MM-DD'));
      score = d3.mean(sourceData, d => d.score).toFixed(2);
      topContentType = sourceData[0].top_content_type;
      sentiment = d3.mean(sourceData, d => d.avg_sentiment).toFixed(2);
      numPosts = d3.sum(sourceData, d => d.num_posts);
      numEngagements = d3.sum(sourceData, d => d.num_engagements);
      avgEngagements = (numEngagements/numPosts).toFixed(2);
    }
      
    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Date: </span>${date}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Brand: </span>${brand}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Top Content Type: </span>${topContentType}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Nichefire Score: </span>${score}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Posts: </span>${numPosts}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Engagements: </span>${numEngagements}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Avg. Engagements Per Post: </span>${avgEngagements}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Sentiment Score: </span>${sentiment}</div>
              </div>`
        );
    
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  function clickHandle(d, i, n) {
    let brand = n[i].parentNode.id,
      startDate,
      endDate;
    
    if (props.timeScale === 'daily') {
      startDate = moment.utc(d.key);
      endDate = moment.utc(d.key);
    } 
    else if (props.timeScale === 'weekly') {
      startDate = d3.utcWeek(moment.utc(d.key));
      endDate = moment.utc(startDate).add(6, 'days');
    }
    else if (props.timeScale === 'monthly') {
      startDate = d3.utcMonth(moment.utc(d.key));
      endDate = moment.utc(startDate).add(1, 'months');
    }
    props.openModal(brand, startDate, endDate);
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
      {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
      <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  )
}