import * as d3 from 'd3';

let colorMap = {
  'Educational': '#ff7f00',
  'User engagement': '#28b9d1',
  'Products/services': '#6dbe45',
  'Entertainment': '#f15b5c',
  'Services/technologies': '#617abc',
  'Locations': '#f6c350',
  'Customer feature': '#8f5593',
  'Social responsibility': '#b3dce7',
  'Events': '#cede5c',
  'Contests/sweepstakes': '#83ccb0',
  'Company pride': '#28b9d1',
  'Business banking': '#ff7f00',
  'Business credit cards': '#28b9d1',
  'Business financial planning': '#6dbe45',
  'Business investing': '#f15b5c',
  'Business loans/credit': '#617abc',
  'Credit cards': '#f6c350',
  'Home financing': '#8f5593',
  'Investing/wealth management': '#b3dce7',
  'Uncategorized': '#b3dce7',
  'Merchant services': '#cede5c',
  'Online/mobile': '#83ccb0',
  'Personal banking': '#758ab6',
  'Personal/auto loans': '#38A1F3',
  'Student loans': '#d56e6a',
  'FB Status': '#758ab6',
  'FB Link': '#3b5998',
  'FB Photo': '#9daccb',
  'FB Video': '#293e6a',
  'TW Status': '#38A1F3',
  'TW Link': '#87c6f7',
  'TW Photo': '#b5ddfc',
  'YT Video': '#c4302b',
  'IN Photo': '#b488d2',
  'IN Image': '#833AB4',
  'IN Carousel_album': '#6f1da7',
  'IN Video': '#540d85',
  'IG Photo': '#b488d2',
  'IG Image': '#833AB4',
  'IG Carousel_album': '#6f1da7',
  'IG Video': '#540d85',
};

let colorArray = [
  '#ff7f00',
  '#28b9d1',
  '#6dbe45',
  '#f15b5c',
  '#617abc',
  '#f6c350',
  '#8f5593',
  '#b3dce7',
  '#cede5c',
  '#83ccb0'
]

export function getContentTypeColor(type) {
  return (colorMap[type]) ? colorMap[type] : '#a9a9a9';
}

export function getScale(types) {
  if (types.length > 0) {
    let colors = [];
    types.forEach(type => colors.push(colorMap[type]));
    let scale = d3.scaleOrdinal(colors)
      .domain(types);
    return scale;
  }
}

export function getBrandScale(brands) {
  let filteredBrands = brands.filter(b => (b.active === true))
  let colors = filteredBrands.map(b => b.color);
  let brandNames = filteredBrands.map(b => b.brand);
  //console.log('returning colors for brands...', brandNames);
  let scale = d3.scaleOrdinal(colors)
    .domain(brandNames);
  return scale;
}

let engagementColors = {
  'Comments': '#ff7f00',
  'Shares': '#28b9d1',
  'Reactions': '#6dbe45',
} 

export function getEngagementTypeColor(type) {
  return (engagementColors[type]) ? engagementColors[type] : '#a9a9a9';
}

export function getEngagementTypeScale(types) {
  if (types.length > 0) {
    let colors = [];
    types.forEach(type => colors.push(engagementColors[type]));
    let scale = d3.scaleOrdinal(colors)
      .domain(types);
    return scale;
  }
}

export function assignBrandColors(brands) {
  let numBrands = brands.length;
  let colorReturn = [];
  for (let i = 0; i < numBrands; i++) {
    colorReturn[brands[i]] = colorArray[i];
  }
  return colorReturn;
}

let postTypeColors = {
  'FB Status': '#758ab6',
  'FB Link': '#3b5998',
  'FB Photo': '#9daccb',
  'FB Video': '#293e6a',
  'TW Status': '#38A1F3',
  'TW Link': '#87c6f7',
  'TW Photo': '#b5ddfc',
  'YT Video': '#c4302b',
  'IN Photo': '#b488d2',
  'IN Image': '#833AB4',
  'IN Carousel_album': '#6f1da7',
  'IN Video': '#540d85',
}

export function getPostTypeColor(type) {
  return (postTypeColors[type]) ? postTypeColors[type] : '#a9a9a9';
}

export function getPostTypeScale(types) {
  if (types.length > 0) {
    let colors = [];
    types.forEach(type => colors.push(postTypeColors[type]));
    let scale = d3.scaleOrdinal(colors)
      .domain(types);
    return scale;
  }
}