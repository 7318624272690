import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    loadingDiv: {
        width: '100%',
        textAlign: 'center',
    }
});

function LoadingSpinner(props) { 
    let {classes} = props;
    return (<div className={classes.loadingDiv}>
        <CircularProgress color="secondary" />
    </div>);
}

export default withStyles(styles)(LoadingSpinner);