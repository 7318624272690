import React, { useContext, useState } from 'react';
import GlobalContext from './GlobalContext';
import axios from 'axios';
import LoginForm from './LoginForm';
import LoadingSpinner from './components/ui/LoadingSpinner';
import { withStyles } from '@material-ui/core/styles';
import {
  Snackbar,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import packageJson from '../package.json';
global.appVersion = packageJson.version;
axios.defaults.withCredentials = true;

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  }
});

function Login(props) {
  const GLOBAL = useContext(GlobalContext),
    { classes } = props,
    [formVals, setFormVals] = useState({
      username: '',
      password: '',
    }),
    [loading, setLoading] = useState(false),
    [authError, setAuthError] = useState(false);

  const handleChange = event => {
    setFormVals({
      ...formVals,
      [event.target.id]: event.target.value
    });
  }

  const handleSubmit = event => {
    setLoading(true);
    console.log('logging in...');
    axios.post(process.env.REACT_APP_SERVER_URL + '/auth/login', formVals)
      .then(res => handleLoginRes(res.data))
      .catch(err => console.log(err));
    event.preventDefault();
  }

  const handleLoginRes = data => {
    if (data.username) {
      console.log('login successful...', data);
      GLOBAL.user.set(data);
    }
    else {
      setLoading(false);
      setAuthError(true);
      console.log("Error: ", data);
    }
  }


  

  if (!loading) {
    return (
      <React.Fragment>
        {(authError) &&
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={authError}
            autoHideDuration={6000}
            onClose={() => setAuthError(false)}
            ContentProps={{
              'aria-describedby': 'authError',
            }}
            message={<span id="authError">Username or password incorrect.</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => setAuthError(false)}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        }
        <LoginForm
          submitHandler={handleSubmit}
          changeHandler={handleChange} 
        />
        <Typography style={{ fontSize: '0.8em', textAlign: 'center' }}>&copy; Nichefire 2020 &middot; {global.appVersion}</Typography>
      </React.Fragment>
    );
  } else {
    return (
      <div style={{ marginTop: '20px' }}>
        <LoadingSpinner />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
