import React from 'react';
import BrandSentimentSpectrum from './graphs/BrandSentimentSpectrum';

function ConversationTopicsModal(props) {
  return (
    <BrandSentimentSpectrum 
      brand={props.brand} 
      convTopic={props.topic} 
      productService={props.productService} 
    />
  );
}

export default ConversationTopicsModal;