import { createMuiTheme } from '@material-ui/core/styles';

export const mainTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#ff7f00',
        },
        secondary: {
            main: '#28b9d1',
        }
    },
    overrides: {
        MuiDivider: {
            middle: {
                marginTop: 15,
                marginBottom: 15,
            }
        },
    },
});