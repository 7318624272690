import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from '../GlobalContext';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BrandSentimentSpectrum from './graphs/BrandSentimentSpectrum';
import SentimentByTopic from './graphs/SentimentByTopic';
import axios from 'axios';

function SentimentScoreModal(props) {
  let { classes } = props;
  const GLOBAL = useContext(GlobalContext),
    [graphData, setGraphData] = useState([]),
    {brand} = props;

  // on modal open
  useEffect(() => {
    if (brand) {
      setGraphData([]);
      let options = {
        startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
        endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
        brands: [brand],
        platforms: GLOBAL.currentPlatforms.get,
        boosted: GLOBAL.boosted.get,
        excludeChildBrands: GLOBAL.excludeChildBrands.get,
      };
      
      axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/avg-sentiment-by-platform', { queryOptions: options })
        .then(res => {
          //console.log('modal data: ', res.data);
          setGraphData(res.data);
        });
    }
  }, [brand]);

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={3}>
          <Typography className={classes.brandName}>{brand}</Typography>
          <Typography className={classes.platformHeader}>Avg. Platform Sentiment</Typography>
          {(graphData.length > 0) &&
            graphData.map(platform => (
              <Typography className={classes.platformDetails} key={platform.platform}>{platform.platform}: {platform.platform_avg}</Typography>
            ))}
        </Grid>
        <Grid item xs={9}>
          <SentimentByTopic brand={brand} />
        </Grid>
      </Grid>
      <BrandSentimentSpectrum
        brand={brand}
        showTopBar={false}
      />
    </React.Fragment>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
    margin: '0 auto 16px',
    border: '3px solid #e5e5e5',
    borderRadius: '10px',
    width: '80%',
  },
  brandName: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  platformHeader: {
    fontWeight: 400,
    fontSize: '90%',
    marginBottom: theme.spacing(2),
  },
  platformDetails: {
    fontSize: '90%',
  }
});

export default withStyles(styles)(SentimentScoreModal);