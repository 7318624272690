import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import moment from 'moment';
import {debounce} from 'lodash';

import LoadingSpinner from '../ui/LoadingSpinner';

export default function PostPerformanceTimeline(props) {
  // configuration variables
  const [graphData, setGraphData] = useState([]),
    d3Container = useRef(null)
  
  const margin = {
    top: 25, 
    right: 25,
    bottom: 25, 
    left: 50
  }

  // establish min. width and fixed height
  const width = 400 - margin.left - margin.right;
  const height = 200 - margin.top - margin.bottom;

  useEffect(() => {
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/post-performance-timeline', { postId: props.postId })
      .then(result => { setGraphData(result.data); }) 
      .catch(err => console.log(err));
  }, [props.postId]);

  useEffect(() => {
    if (graphData.length > 0) drawChart();
  }, [graphData])

  // clean up event listeners on unmount
  useEffect(() => {
    return () => {
      d3.select(window).on(`resize.${props.postId}`, null);
    }
  })

  function drawChart() {
    const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;
    const initialWidth = getCurrentWidth();

    //console.log(initialWidth);

    const svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', initialWidth + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit')

    const extent = d3.extent(graphData, d => moment.utc(new Date(d.date.value)));

    const x = d3.scaleUtc()
      .domain(extent)
      .range([0, initialWidth]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(graphData, d => d.score) + 1])
      .range([height, 0])

    const xAxis = d3.axisBottom(x)
      .tickFormat(d3.utcFormat("%b %d"));

    const yAxis = d3.axisLeft(y)
      .ticks(6);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis);

    let line = d3.line()
      .x(d => x(moment.utc(new Date(d.date.value))))
      .y(d => y(d.score))
      .curve(d3.curveMonotoneX);
    
    svg.append('g')
      .append('path')
      .datum(graphData)
        .attr('class', 'line')
        .style('stroke', '#28b9d1')
        .attr('d', line);

    svg.selectAll('.dot')
      .data(graphData)
      .join('circle')
        .attr('class', 'dot')
        .attr('cx', d => x(moment.utc(new Date(d.date.value))))
        .attr('cy', d => y(d.score))
        .attr('r', 5.5)
        .attr('fill', '#28b9d1')
        .on('mouseover', tooltipOpen)
        .on('mousemove', tooltipMove)
        .on('mouseout', tooltipClose);

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("fill", "currentColor")
      .text('Nichefire Score');

    const resize = () => {
      const update = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      update.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      update.select('.x.axis')
        .call(xAxis); 

      update.select('.line')
        .attr('d', line);

      update.selectAll('.dot')
        .attr('cx', d => x(moment.utc(new Date(d.date.value))))
        .attr('cy', d => y(d.score))
    }

    d3.select(window).on(`resize.${props.postId}`, debounce(resize, 300))
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this),
      date = moment.utc(d.date).format('MMM DD'),
      score = d.score
      
    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Date: </span>${date}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Nichefire Score: </span>${score}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Reactions: </span>${d.num_reactions}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Comments: </span>${d.num_comments}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Shares: </span>${d.num_shares}</div>
              </div>`
        );
    
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
      {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
      <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  );
}