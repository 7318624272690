import React, { useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import {
  FilterList,
  AccountCircle,
  ChevronRight, 
  ExitToApp,
  Settings,
  Work
} from '@material-ui/icons';

import GlobalContext from './GlobalContext';
import UpgradeModal from './components/ui/UpgradeModal';
import { updateLoggedInUser } from './redux/actions/index';
import UserSettingsModal from './components/ui/UserSettingsModal';

function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser,
    userType: state.userType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateLoggedInUser
  }
}

function TopBar(props) {
  const [showModal, toggleModal] = useState(),
    [redirect, setRedirect] = useState(false),
    [anchorEl, setAnchorEl] = useState(null),
    [accountModalOpen, setAccountModalOpen] = useState(false)
  const GLOBAL = useContext(GlobalContext);
  let classes = useStyles();

  const logOut = () => {
    axios.get(process.env.REACT_APP_SERVER_URL + '/auth/logout')
      .then(
        res => {
          props.dispatch(props.updateLoggedInUser(false));
          setRedirect(true);
          window.location.reload();
        }
      ).catch(err => console.log(err));
  }

  // popover functions

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (props.showNav === true) {
    if (props.userType === 'demo') {
      return (
        <React.Fragment>
          {(redirect) && <Redirect to='/' />}
          <UpgradeModal modalOpen={showModal} closeModal={() => toggleModal(false)} />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Link to="/projects"><img src="/assets/nichefire-logo.png" id="logo" alt="Nichefire logo" /></Link>
              <div id="top-nav">
                <Link to="/overview" className={(props.currentPage === '/overview') ? classes.active : classes.link}>Overview</Link>
                <a href="/#" onClick={() => { console.log('toggle'); toggleModal(true); console.log(showModal); }}>Strategies</a>
                <a href="/#" onClick={() => toggleModal(true)}>Audience Engagement</a>
                <a href="/#" onClick={() => toggleModal(true)}>Search</a>
              </div>
              <div className={classes.rightActions}>
                <Link to="/auth/logout" onClick={handleClick}>Logout</Link>
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.toolbar} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {(redirect) && <Redirect to='/' />}
          <UserSettingsModal
            open={accountModalOpen}
            close={() => {
              setAccountModalOpen(false)
            }}
          />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Link to="/projects"><img src="/assets/nichefire-logo.png" id="logo" alt="Nichefire logo" /></Link>
              <div id="top-nav">
                <Link to="/overview" className={(props.currentPage === '/overview') ? classes.active : classes.link}>Overview</Link>
                <Link to="/tactics" className={(props.currentPage === '/tactics') ? classes.active : classes.link}>Strategies</Link>
                <Link to="/engagements" className={(props.currentPage === '/engagements') ? classes.active : classes.link}>Audience Engagement</Link>
                <Link to="/search" className={(props.currentPage === '/search') ? classes.active : classes.link}>Search</Link>
              </div>
              <div className={classes.rightActions}>
                <Tooltip
                  title='Account'
                >
                  <IconButton
                    onClick={handleClick}
                  >
                    <AccountCircle
                      className={classes.actionIcon}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Global Filters"
                >
                  {(!props.filterStatus) 
                  ?
                    <IconButton
                      onClick={() => {
                        props.openFilter();
                        setTimeout(() => {
                          let resizeEvent = new Event('resize');
                          window.dispatchEvent(resizeEvent);
                        }, 200);
                      }}
                    >
                      <FilterList
                        className={classes.actionIcon}
                      />
                    </IconButton>
                  :
                    <IconButton
                      onClick={() => {
                        props.closeFilter();
                        setTimeout(() => {
                          let resizeEvent = new Event('resize');
                          window.dispatchEvent(resizeEvent);
                        }, 200);
                      }}
                    >
                      <ChevronRight
                        className={classes.actionIcon}
                      />
                    </IconButton>
                  }
                </Tooltip>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <List component='nav'>
                    {(GLOBAL.user.get.user_scope === 'admin') &&
                      <ListItem
                        button
                        className={classes.subMenuItem}
                        onClick={() => {
                          
                        }}
                      >
                        <ListItemIcon
                          className={classes.listItemIcon}
                        >
                          <Work />
                        </ListItemIcon>
                        <ListItemText
                          primary='Admin'
                        />
                      </ListItem>
                    }
                    <ListItem
                      button
                      className={classes.subMenuItem}
                      onClick={() => {
                        setAccountModalOpen(true);
                      }}
                    >
                      <ListItemIcon
                        className={classes.listItemIcon}
                      >
                        <Settings />
                      </ListItemIcon>
                      <ListItemText
                        primary='Account Settings'
                      />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.subMenuItem}
                      onClick={() => {
                        logOut();
                      }}
                    >
                      <ListItemIcon
                        className={classes.listItemIcon}
                      >
                        <ExitToApp />
                      </ListItemIcon>
                      <ListItemText
                        primary='Logout'
                      />
                    </ListItem>
                  </List>
                </Popover>
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.toolbar} />
        </React.Fragment>
      );
    }
  }
  else {
    return (
      <React.Fragment>
        {(redirect) && <Redirect to='/' />}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Link to="/projects"><img src="/assets/nichefire-logo.png" id="logo" width="175" alt="Nichefire logo" /></Link>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
      </React.Fragment>
    );
  }
}

let useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
  },
  toolbar: theme.mixins.toolbar,
  link: {
    '&:hover': {
      color: theme.palette.primary.main + '!important',
    },
  },
  active: {
    color: theme.palette.primary.main + '!important',
  },
  actionIcon: {
    fill: 'rgba(0,0,0,.65)',
    fontSize: '26px',
  },
  rightActions: {
    marginLeft: 'auto',
    '& a': {
      textDecoration: 'none',
    }
  },
  subMenuItem: {
    padding: 10,
  },
  listItemIcon: {
    minWidth: '36px'
  }
}));

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);