import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux/reducers/index';
import { ThemeProvider } from '@material-ui/styles';
import { mainTheme } from './themes/mainTheme';
import { BrowserRouter as Router } from "react-router-dom";
import CacheBuster from './CacheBuster';
import GlobalContext from './GlobalContext';
import moment from 'moment';

const store = createStore(rootReducer);
window.store = store;

let Wrapper = () => {
  const startDate = moment().subtract(30, 'days'),
    endDate = moment()

  const [user, setUser] = useState(null),
    [projects, setProjects] = useState([]),
    [currentProjectId, setCurrentProjectId] = useState(null),
    [currentBrands, setCurrentBrands] = useState([]),
    [currentDates, setCurrentDates] = useState({
      start: startDate,
      end: endDate
    }),
    [currentPlatforms, setCurrentPlatforms] = useState(
      ['Facebook', 'Twitter', 'Instagram', 'YouTube']
    ),
    [boosted, setBoosted] = useState(false);
  
  const [excludeChildBrands, setExcludeChildBrands] = useState([]);

  const storeContext = {
    user: {
      get: user,
      set: setUser,
    },
    projects: {
      get: projects,
      set: setProjects
    },
    currentProjectId: {
      get: currentProjectId,
      set: setCurrentProjectId
    },
    currentBrands: {
      get: currentBrands,
      set: setCurrentBrands
    },
    currentDates: {
      get: currentDates,
      set: setCurrentDates
    },
    currentPlatforms: {
      get: currentPlatforms,
      set: setCurrentPlatforms
    },
    boosted: {
      get: boosted,
      set: setBoosted
    },
    excludeChildBrands: {
      get: excludeChildBrands,
      set: setExcludeChildBrands,
    },
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <GlobalContext.Provider value={storeContext}>
            <Provider store={store}>
              <ThemeProvider theme={mainTheme}>
                <Router>
                  <App />
                </Router>
              </ThemeProvider>
            </Provider>
          </GlobalContext.Provider>
        );
      }}
    </CacheBuster>
  );
}

ReactDOM.render(
  <Wrapper />,
  document.getElementById('root')
);