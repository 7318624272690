import * as constants from '../actions/index';
import moment from 'moment';
import { assignBrandColors } from '../../themes/CommonColors';

const initialState = { 
    width: 760,
    height: 300,
    leaderboardData: [],
    linechartData: [],
    harperData: [],
    currentProject: 'none',
    modalIsOpen: false,
    demoContent: 'Loading...',
    projectBrands: [],
    activePlatforms: ['Facebook', 'Twitter', 'Instagram', 'YouTube'],
    numPlatforms: 4,
    globalDates: { 
        start: moment('2019-08-01'),
        end: moment('2019-08-14') 
    },
    loggedInUser: false,
    userScope: false,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case constants.OPEN_MODAL: 
            return { ...state, modalIsOpen: true };

        case constants.UPDATE_OVERVIEW_LEADERBOARD: 
            return { ...state, leaderboardData: action.payload.data };

        case constants.UPDATE_OVERVIEW_LINECHART: 
            return { ...state, linechartData: action.payload.data };
        
        case constants.UPDATE_OVERVIEW_HARPER: 
            return { ...state, harperData: action.payload.data };

        case constants.CLOSE_MODAL: 
            return Object.assign({}, state, {
                modalIsOpen: false, 
                demoContent: 'Loading...'
            });

        case constants.UPDATE_CONTENT: 
            return { ...state, demoContent: action.payload }

        case constants.UPDATE_GLOBAL_DATES:
            return { ...state, 
                globalDates: {
                    start: action.payload.start,
                    end: action.payload.end
                }
            }

        case constants.UPDATE_FOCUSED_INPUT:
            return { ...state, focusedInput: action.payload }

        case constants.UPDATE_NETWORK_GRAPH:
            return { ...state, networkGraphData: action.payload }
        
        case constants.UPDATE_FEED_MAKEUP:
            return { ...state, feedMakeupData: action.payload }

        case constants.UPDATE_PERFORMANCE_GRAPH:
            return { ...state, performanceGraphData: action.payload }
    
        case constants.UPDATE_RESPONSES_CIRCLES:
            return { ...state, responsesCirclesData: action.payload }  
        
        case constants.UPDATE_RESPONSES_SENTIMENT:
            return { ...state, responsesSentimentData: action.payload }   

        case constants.UPDATE_RESPONSES_SPECTRUM:
            return { ...state, responsesSpectrumData: action.payload }     

        case constants.UPDATE_LOGGEDIN_USER:
            return { ...state, loggedInUser: action.payload.username, userType: action.payload.user_type }

        case constants.UPDATE_USER_SCOPE:
            return { ...state, userScope: action.payload }

        case constants.UPDATE_PROJECT_BRANDS: {
            let numActive = 0;
            let newBrands = action.payload;
            newBrands.forEach(b => {
                if (b.active === true) numActive++;
            })
            return { ...state, projectBrands: newBrands, numBrands: numActive }
        }

        case constants.UPDATE_ACTIVE_PLATFORMS: {
            let newNumPlatforms = action.payload.length;
            return { ...state, activePlatforms: action.payload, numPlatforms: newNumPlatforms }
        }

        case constants.REPLACE_PROJECT_BRANDS:
            let newProjectBrands = [];
            let brandColors = assignBrandColors(action.payload);
            action.payload.map(b => newProjectBrands.push({brand: b, active: true, color: brandColors[b]}))
            let sorted = newProjectBrands.sort((a, b) => (a.brand > b.brand) ? 1 : -1);
            return { ...state, projectBrands: sorted }

        case constants.UPDATE_CUR_EDIT_PROJECT:
            return { ...state, currentEditProject: action.payload }

        case constants.UPDATE_CUR_BRAND_DROP:
            return { ...state, currentBrandDrop: action.payload }

        case constants.SET_AVAILABLE_BRANDS:
            return { ...state, currentAvailableBrands: action.payload }

        case constants.UPDATE_CURRENT_PROJECT:
            return { ...state, currentProject: action.payload }

        default: 
            return state;
            
    }
}

export default rootReducer;