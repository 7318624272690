import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Modal from 'react-modal';
import Close from '@material-ui/icons/Close'

//import GlobalContext from '../GlobalContext';
import SentimentScore from '../components/graphs/SentimentScore';
import ConversationTopics from '../components/graphs/ConversationTopics'
import CustomTooltip from '../CustomTooltip';
import PostExplorerModal from '../PostExplorerModal';
import ConversationTopicsModal from '../components/ConversationTopicsModal';
import SentimentScoreModal from '../components/SentimentScoreModal';

function EngagementsPage(props) {
  //const GLOBAL = useContext(GlobalContext),
  const { classes } = props,
    [modal, setModal] = useState({
      open: false,
      type: null,
      brand: null,
      date: null,
      contentType: null,
    });

  const openModal = (modalType, brand, date, contentType) => {
    setModal({ ...modal, open: true, type: modalType, brand: brand, date: date, contentType: contentType })
  }

  const openConvModal = (brand, topic, productService) => {
    setModal({
      ...modal,
      open: true,
      type: 'conv_topics',
      brand: brand,
      topic: topic,
      productService: productService
    });
  }

  return (
    <React.Fragment>
      <Modal isOpen={modal.open}
        onRequestClose={() => setModal({ ...modal, open: false })}
        style={customModalStyles}
      >
        <Close className={classes.closeButton} onClick={() => setModal({ ...modal, open: false })} />
        {(modal.type === 'post_explorer') &&
          <PostExplorerModal
            brand={modal.brand}
            date={modal.date}
          />
        }
        {(modal.type === 'sentiment_score') &&
          <SentimentScoreModal 
            brand={modal.brand}
          />
        }
        {(modal.type === 'conv_topics') &&
          <ConversationTopicsModal 
            brand={modal.brand}
            topic={modal.topic}
            productService={modal.productService}
          />
        }
      </Modal>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Sentiment Score
          <CustomTooltip helpText="This visual shows the average sentiment using advanced, nuanced-sentiment scoring. The range goes from -10, most negative, to 10, most positive." />
        </Typography>
        <SentimentScore 
          openModal={openModal}
        />
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.graphTitle}>
          Conversation Topics
          <CustomTooltip helpText="Explore Share of Voice by brand, conversation topic, and product/service. Click to drill down, double-click to open a filtered Sentiment Spectrum." />
        </Typography>
        <ConversationTopics 
          openModal={openConvModal}
        />
      </Paper>
    </React.Fragment>
  );
}

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  content: {
    top: '10%',
    left: '2.5%',
    bottom: '10%',
    right: '2.5%'
  },
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
  },
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
});

export default withStyles(styles)(EngagementsPage);