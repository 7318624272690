import React, { useState, useEffect, useContext } from 'react';
import { 
  Grid, 
  Tabs, 
  Tab, 
  Box, 
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-modal';

import GlobalContext from './GlobalContext';
import PostEmbed from './components/ui/PostEmbed';
import PostSentimentSpectrum from './components/graphs/PostSentimentSpectrum';
import PostPerformanceTimeline from './components/graphs/PostPerformanceTimeline';
import CommentResonance from './components/graphs/CommentResonance';
import LoadingSpinner from './components/ui/LoadingSpinner';
import ConversationTopicsModal from './components/ConversationTopicsModal';

function PostExplorerModal(props) {
  const GLOBAL = useContext(GlobalContext);
  let { classes } = props;
  let [value, setValue] = useState(0);
  let [data, setData] = useState([]);
  let [sortBy, setSortBy] = useState('Post Timestamp'),
    [loading, setLoading] = useState(true)
  
  const [sentimentSpectrumModal, setSentimentSpectrumModal] = useState({
    open: false,
    topic: null,
  });

  // fetch data on modal open
  useEffect(() => {
    setData([]);
    let queryOptions = {
      startDate: props.startDate ? moment.utc(props.startDate).format('Y-MM-DD').toString() : (props.date !== "global") ? moment.utc(props.date).format('Y-MM-DD').toString() : GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: props.endDate ? moment.utc(props.endDate).format('Y-MM-DD').toString() : (props.date !== "global") ? moment.utc(props.date).format('Y-MM-DD').toString() : GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brand: props.brand,
      platforms: GLOBAL.currentPlatforms.get,
      contentType: props.contentType,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands,
    };
    //console.log('updating...', options);

    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/fetch-posts', { queryOptions })
      .then(res => {
        console.log('modal data: ', res.data);
        setData(res.data);
        setLoading(false)
      });
  }, []);

  useEffect(() => {
    if (data) {
      let newData = [...data];
      if (sortBy === 'Post Timestamp') {
        newData.sort((a, b) => new Date(a.date) < new Date(b.date))
      }
      if (sortBy === 'Post Performance Score') {
        newData.sort((a, b) => a.performance_score < b.performance_score)
      }
      if (sortBy === 'Avg. Sentiment Score') {
        newData.sort((a, b) => a.avg_sentiment > b.avg_sentiment)
      }
      if (sortBy === 'Engagements') {
        newData.sort((a, b) => a.num_reactions < b.num_reactions)
      }
      setData(newData);
    }
  }, [sortBy]);

  let handleChange = (event, newValue) => setValue(newValue);

  const openSentimentSpectrum = (topic) => {
    setSentimentSpectrumModal({
      ...sentimentSpectrumModal,
      open: true,
      topic,
    });
  }

  if (!loading && data.length > 0) {
    let returnModalContent = data.map(d => (
      <Grid container spacing={1} key={d.url} className={classes.postRow}>
        <Grid item md={4} className={classes.iframeContainer}>
          <PostEmbed
            platform={d.platform}
            postId={d.post_id}
            postUrl={d.url}
          />
        </Grid>
        <Grid item md={8} className={classes.postExplorer}>
          <Tabs value={value} indicatorColor="secondary" textColor="secondary" variant="fullWidth" onChange={handleChange} className={classes.tabs}>
            <Tab label="Post Overview" />
            <Tab label="Conversation Sentiment" />
            <Tab label="Conversation Topics" />
          </Tabs>
          {(value === 0) &&
            <Box className={classes.tabContainer}>
              <Grid container>
                <Grid item md={3}>
                  <div><span style={{ fontWeight: 500 }}>Posted</span> {moment.utc(d.date).fromNow()}</div>
                  <div><span style={{ fontWeight: 500 }}>Post Performance Score:</span> {d.performance_score ? d.performance_score.toFixed(2) : 'N/A'}</div>
                  {(d.avg_sentiment !== null) && <div><span style={{ fontWeight: 500 }}>Avg. Sentiment Score:</span> {d.avg_sentiment}</div>}
                  {/*(d.num_comments > 0) && <div><span style={{ fontWeight: 500 }}>Number of Comments:</span> {d.num_comments}</div>*/}
                  {/*(d.num_shares > 0) && <div><span style={{ fontWeight: 500 }}>Number of Shares:</span> {d.num_shares}</div>*/}
                  {/*(d.num_reactions > 0) && <div><span style={{ fontWeight: 500 }}>Number of Reactions:</span> {d.num_reactions}</div>*/}
                  <div><span style={{ fontWeight: 500, float: 'left' }}>Tags:</span></div>
                  <div className={classes.tag}>{d.content_type}</div>
                  {(d.content_appeal !== 'Uncategorized') &&
                    <div className={classes.tag}>{d.content_appeal}</div>
                  }
                  {(d.product_service !== 'Uncategorized') &&
                    <div className={classes.tag}>{d.product_service}</div>
                  }
                </Grid>
                <Grid item md={9}>
                  <Typography className={classes.graphTitle}>
                    Performance Over Time
                  </Typography>
                  <PostPerformanceTimeline postId={d.post_id} />
                </Grid>
              </Grid>
            </Box>
          } {(value === 1) &&
            <Box className={classes.tabContainer}>
              <Typography className={classes.graphTitle}>
                Sentiment Spectrum
              </Typography>
              <PostSentimentSpectrum postId={d.post_id} />
            </Box>
          } {(value === 2) &&
            <Box className={classes.tabContainer}>
              <Typography className={classes.graphTitle}>
                Top Conversation Topics
              </Typography>
              <CommentResonance 
                postId={d.post_id}
                openSentimentSpectrum={openSentimentSpectrum}
              />
            </Box>
          }
        </Grid>
      </Grid>
    ));
    return (
      <Box>
        <Modal isOpen={sentimentSpectrumModal.open}
          onRequestClose={() => setSentimentSpectrumModal({ ...sentimentSpectrumModal, open: false })}
          style={customModalStyles}
        >
          <ConversationTopicsModal 
            brand={props.brand}
            topic={sentimentSpectrumModal.topic}
          />
        </Modal>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="sortBy">Sort by...</InputLabel>
          <Select
            value={sortBy}
            inputProps={{
              name: 'sortBy',
              id: 'sortBy'
            }}
            onChange={e => setSortBy(e.target.value)}
          >
            {['Post Timestamp', 'Post Performance Score', 'Avg. Sentiment Score', 'Engagements'].map(opt => (<MenuItem key={opt} value={opt}>{opt}</MenuItem>))}
          </Select>
        </FormControl>
        {returnModalContent}
      </Box>
    );
  } else if (loading) {
    return (<LoadingSpinner />);
  }
  else if (data.length === 0) {
    return (<Typography>No posts found for the selected date(s).</Typography>)
  }
}

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  content: {
    top: '10%',
    left: '2.5%',
    bottom: '10%',
    right: '2.5%'
  },
}

const styles = theme => ({
  postRow: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: '3px solid #e5e5e5',
    borderRadius: '10px',
  },
  iframeContainer: {
    flexGrow: 2,
  },
  postExplorer: {
    flexShrink: 1,
    border: '2px solid #e5e5e5',
    borderRadius: '10px',
  },
  tabs: {
    marginBottom: theme.spacing(1),
  },
  tabContainer: {
    padding: theme.spacing(1),
    width: '100%',
  },
  loadingDiv: {
    width: '100%',
    textAlign: 'center',
  },
  tag: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: '10px',
    margin: theme.spacing(1),
    display: 'inline-block',
    float: 'left',
    clear: 'both',
  },
  graphTitle: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center'
  },
  formControl: {
    marginBottom: 20,
    minWidth: 120,
  },
});

export default withStyles(styles)(PostExplorerModal);