import React, { useRef, useEffect, useState, useContext } from 'react';
import * as d3 from 'd3';
import legend from 'd3-svg-legend';
import axios from 'axios';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';

import GlobalContext from '../../GlobalContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import { getContentTypeColor, getScale } from '../../themes/CommonColors';

function StrategyBenchmark(props) {
  // configuration variables
  const GLOBAL = useContext(GlobalContext),
    d3Container = useRef(null)
  
  const [graphData, setGraphData] = useState([]),
    [measurement, setMeasurement] = useState(null);

  const margin = {
    top: 25,
    bottom: 50,
    left: 150,
    right: 250,
  };

  // establish min. width and fixed height
  //let width = 900 - margin.left - margin.right;
  let height = 300 - margin.top - margin.bottom;

  const location = useLocation();

  // clean up event listeners on unmount
  useEffect(() => {
    //console.log(location);
    return () => {
      d3.select(window).on(`resize.strategy_benchmark`, null);
    }
  }, [location])

  const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;

  // draw empty graph
  useEffect(() => {
    d3.select(d3Container.current).select('svg').remove();
    setGraphData([]);

    const svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', getCurrentWidth() + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit')

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`); // move x-axis to bottom

    svg.append('g')
      .attr('class', 'y axis')

    svg.append('g')
      .attr('class', 'legend')

    svg.append("text")
      .attr("y", height + margin.top)
      .attr("x", getCurrentWidth() / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr('id', 'sbLabel');

    let queryOptions = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };

    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/strategy-benchmark', { queryOptions })
      .then(res => {
        //console.log('strategy benchmark data: ', res.data);
        setGraphData(res.data);
      }).catch(err => console.log(err));

    /* Query return:
    *  Flattened fields: brand, post_type, content_type, product_service, count, total_engagements, avg_engagements, nichefire_score
    */
  }, [GLOBAL.currentDates.get.start,
  GLOBAL.currentDates.get.end,
  GLOBAL.currentBrands.get,
  GLOBAL.currentPlatforms.get,
  GLOBAL.currentProjectId.get,
  GLOBAL.excludeChildBrands.get])

  // update measurement
  useEffect(() => {
    setMeasurement(props.measurement);
  }, [props.measurement]);

  // prepare data
  useEffect(() => {
    if (graphData.length > 0 && measurement && props.filters) {
      let filters = props.filters,
        show = filters.showMe,
        ctFilters = filters.contentTypes.filter(d => d.active).map(d => d.label),
        pcFilters = filters.productCategories.filter(d => d.active).map(d => d.label),
        ptFiltersRaw = filters.postTypes.filter(d => d.active).map(d => d.label),
        ptFilters = [];

      ptFiltersRaw.forEach(f => {
        switch (f) {
          case 'Status':
            ptFilters.push('FB status', 'TW Status', 'Retweet TW Status', 'Retweet TW Animated_gif');
            break;
          case 'Link':
            ptFilters.push('FB link', 'TW Link', 'Retweet TW Link', 'FB Event');
            break;
          case 'Photo':
            ptFilters.push('FB photo', 'TW Photo', 'Retweet TW Photo', 'IG Photo', 'IN Image', 'IG Carousel Album', 'TW Animated_gif');
            break;
          case 'Video':
            ptFilters.push('YouTube Video', 'FB video', 'IG Video', 'FB music', 'TW Video', 'Retweet TW Video');
            break;
          default:
            ptFilters.push('FB status', 'TW Status', 'Retweet TW Status', 'Retweet TW Animated_gif');
        }
      })

      let brands = [...new Set(graphData.map(d => d.brand))];
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let measurementField, showField;
      switch (measurement) {
        case 'Number of Posts':
          measurementField = 'count';
          break;
        case 'Total Engagements':
          measurementField = 'total_engagements';
          break;
        case 'Avg. Engagements':
          measurementField = 'avg_engagements';
          break;
        case 'Nichefire Score':
          measurementField = 'nichefire_score';
          break;
        default:
          measurementField = 'count';
      }
      switch (show) {
        case 'Content Type':
          showField = 'content_type';
          break;
        case 'Product Category':
          showField = 'product_service';
          break;
        case 'Post Type':
          showField = 'post_type';
          break;
        default:
          showField = 'content_type';
      }

      //console.log(showField);
      //setMeasurementVal(showField);

      let groupKeys = [...new Set(graphData.map(d => d[showField]))],
        groupMax = 0,
        data = [];
      brands.forEach(brand => {
        let brandObj = { brand: brand },
          brandTotal = 0;
        groupKeys.forEach(groupKey => {
          let groupItems = graphData.filter(d => (
            d.brand === brand &&
            d[showField] === groupKey &&
            ctFilters.indexOf(d.content_type) >= 0 &&
            ptFilters.indexOf(d.post_type) >= 0
          ));
          if (show === 'Product Category') groupItems = groupItems.filter(d => pcFilters.indexOf(d.product_service) >= 0);
          if (groupItems.length > 0) {
            let groupValues = groupItems.map(d => d[[measurementField]]),
              groupTotal = groupValues.reduce(reducer);
            if (measurementField === 'avg_engagements' || measurementField === 'nichefire_score') {
              groupTotal = groupTotal / groupValues.length;
            }
            brandObj[[groupKey]] = groupTotal;
            brandTotal += groupTotal;
          }
        })
        brandObj['total'] = brandTotal;
        if (brandTotal > groupMax) groupMax = brandTotal;
        data.push(brandObj);
      });
      
      //console.log('strategy benchmark data:', data);
      drawStrategyBenchmark(data, brands, groupKeys, groupMax);
    }
  }, [graphData, measurement, props.filters]);

  function drawStrategyBenchmark(data, brandKeys, groupKeys, graphMax) {
    let svg = d3.select(d3Container.current)
      .select('#graphEdit');

    // define and draw x-axis
    let x = d3.scaleLinear()
      .domain([0, graphMax])
      .range([0, getCurrentWidth()]);
    let xAxis = d3.axisBottom(x)
      .ticks(6);

    // define and draw y-axis
    let y = d3.scaleBand()
      .domain(brandKeys)
      .range([0, height])
      .paddingInner(0.2);
    let yAxis = d3.axisLeft(y);

    // define stack function
    let stack = d3.stack()
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    // draw graph
    let series = svg.selectAll(".series")
      .data(stack.keys(groupKeys)(data))
      .join("g")
      .attr("class", "series")
      .attr("fill", d => getContentTypeColor(d.key))
      .attr('id', d => d.key);

    series.selectAll("rect")
      .data(d => d)
      .join("rect")
        .attr('class', 'dataRect')
        .on('click', clickHandle)
        .on("mouseover", tooltipOpen)
        .on("mousemove", tooltipMove)
        .on("mouseout", tooltipClose);

    // draw legend
    let colorLegend = legend.legendColor()
      .scale(getScale(groupKeys))
      .labelFormat(d3.format(".2f"));
    let legendNode = d3.select(d3Container.current)
      .select('.legend')
      .style('overflow', 'visible')
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', 'translate(' + (getCurrentWidth() + 25) + ',' + (height / 2 - legendHeight / 2) + ')');

    let update = d3.select(d3Container.current)
      .select('#graphEdit')
        .transition()
          .duration(750)

    update.select('.x.axis').call(xAxis);
    update.select('.y.axis').call(yAxis);
    update.select('#sbLabel').text(measurement);
    update.selectAll('.dataRect')
      .attr("x", d => x(d[0]))
      .attr("y", d => y(d.data.brand))
      .attr("width", d => { var w = (x(d[1]) - x(d[0])); return !isNaN(w) ? w : 0; }) 
      .attr("height", d => y.bandwidth())

    const resize = () => {
      console.log('resize triggered');
      const updateSvg = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      updateSvg.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      updateSvg.select('.x.axis')
        .call(xAxis); 

      updateSvg.selectAll('.dataRect')
        .attr('x', d => x(d[0]))
        .attr('width', d => { var w = (x(d[1]) - x(d[0])); return !isNaN(w) ? w : 0; })

      updateSvg.select('.legend')
        .attr('transform', `translate(${getCurrentWidth() + 25},${height / 2 - legendHeight / 2})`);
    }

    d3.select(window).on(`resize.strategy_benchmark`, debounce(resize, 300))  
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > getCurrentWidth() + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this),
      contentType = n[i].parentNode.id,
      //postNum = d.data[contentType],
      brand = d.data.brand;
      //share = (postNum / d.data.total * 100).toFixed(2)

    //console.log('tooltip:', contentType, postNum, brand, share)
      
    let showField;
    switch (props.filters.showMe) {
      case 'Content Type':
        showField = 'content_type';
        break;
      case 'Product Category':
        showField = 'product_service';
        break;
      case 'Post Type':
        showField = 'post_type';
        break;
      default:
        showField = 'content_type';
    }

    let sourceData = graphData.filter(f => f.brand === brand && f[showField] === contentType);

    //console.log(sourceData);

    let score = d3.mean(sourceData, d => d.nichefire_score).toFixed(2),
      numPosts = d3.sum(sourceData, d => d.count),
      numEngagements = d3.sum(sourceData, d => d.total_engagements),
      avgEngagements = (numEngagements/numPosts).toFixed(2);

    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Brand: </span>${brand}</div>
                <div class="tooltipLine"><span style="font-weight: bold">${props.filters.showMe}: </span>${contentType}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Nichefire Score: </span>${score}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Posts: </span>${numPosts}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Total Engagements: </span>${numEngagements}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Avg. Engagements Per Post: </span>${avgEngagements}</div>
              </div>`
        );
    
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }

  function clickHandle(d, i, n) {
    let brand = d.data.brand;
    let contentType = n[i].parentNode.id;
    props.openModal(brand, GLOBAL.currentDates.get.start, GLOBAL.currentDates.get.end, contentType);
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
      {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
      <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  );
}

export default StrategyBenchmark;