import React, { useContext, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import axios from "axios";

import CustomTooltip from "../../CustomTooltip";
import GlobalContext from "../../GlobalContext";
import ErrorMessage from "./ErrorMessage";

function UserSettingsModal(props) {
  const { classes } = props;
  const GLOBAL = useContext(GlobalContext);

  const [formVals, setFormVals] = useState({
    newEmail: "",
    confirmEmail: "",
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  // error message state
  const [error, setError] = useState({
    open: false,
    message: "",
  });

  const handleChange = (e) => {
    setFormVals({ ...formVals, [e.target.id]: e.target.value });
  };

  const changeEmail = (e) => {
    console.log("changing email...", formVals);
    e.preventDefault();

    if (formVals.newEmail !== formVals.confirmEmail) {
      setError({
        open: true,
        message: "The email addresses you entered do not match.",
      });
      return;
    }

    if (!formVals.newEmail || !formVals.confirmEmail) {
      setError({
        open: true,
        message: "You must enter and confirm a new email address.",
      });
      return;
    }

    if (formVals.newEmail === GLOBAL.user.get.username) {
      setError({
        open: true,
        message: "That's already your email address.",
      });
      return;
    }

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/auth/change-email", formVals)
      .then((res) => {
        setError({
          open: true,
          message: "Email successfully changed.",
        });
        GLOBAL.user.set({
          ...GLOBAL.user.get,
          username: formVals.newEmail,
        });
      })
      .catch((err) => {
        console.log(err);
        setError({
          open: true,
          message: err,
        });
      });
  };

  const changePassword = (e) => {
    console.log("changing password...", formVals);
    e.preventDefault();

    if (formVals.newPassword !== formVals.confirmNewPassword) {
      setError({
        open: true,
        message: "The new passwords you entered do not match.",
      });
      return;
    }

    if (!formVals.oldPassword) {
      setError({
        open: true,
        message: "You must enter your current password.",
      });
      return;
    }

    if (!formVals.newPassword || !formVals.confirmNewPassword) {
      setError({
        open: true,
        message: "You must enter and confirm a new password.",
      });
      return;
    }

    if (formVals.newPassword === formVals.oldPassword) {
      setError({
        open: true,
        message:
          "You entered the same password for your current password and new password.",
      });
      return;
    }

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/auth/change-password", {
        ...formVals,
        username: GLOBAL.user.get.username,
      })
      .then((res) => {
        setError({
          open: true,
          message: "Password successfully changed.",
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setError({
          open: true,
          message: err.response.data.message,
        });
      });
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") {
      setError({
        open: true,
        message: "An unknown error occurred.",
      });
      return;
    }

    if (response.error) {
      setError({
        open: true,
        message: response.error.message,
      });
      return;
    }

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/auth/connect-facebook", {
        uid: GLOBAL.user.get._id,
        fbID: response.userID,
        accessToken: response.accessToken,
      })
      .then((res) => {
        console.log(res.data);
        setError({
          open: true,
          message: "Facebook account successfully connected.",
        });
      })
      .catch((err) => {
        console.log(err);
        setError({
          open: true,
          message: err,
        });
      });
  };

  return (
    <Dialog open={props.open} onClose={() => props.close()}>
      <ErrorMessage
        open={error.open}
        errorMessage={error.message}
        close={() => setError({ open: false, message: "" })}
      />
      <DialogTitle>Account Settings</DialogTitle>
      <DialogContent>
        <Paper className={classes.paper} variant="outlined">
          <Typography className={classes.actionTitle}>
            Change Email Address
          </Typography>
          <Typography className={classes.actionSubtitle}>
            Current Email Address: {GLOBAL.user.get.username}
          </Typography>
          <form noValidate autoComplete="off" onSubmit={changeEmail}>
            <TextField
              id="newEmail"
              label="New Email Address"
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={handleChange}
              value={formVals.newEmail}
            />
            <TextField
              id="confirmEmail"
              label="Confirm New Email Address"
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={handleChange}
              value={formVals.confirmEmail}
            />
            <Button
              variant="contained"
              className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Paper>
        <Paper className={classes.paper} variant="outlined">
          <Typography className={classes.actionTitle}>
            Change Password
          </Typography>
          <form noValidate autoComplete="off" onSubmit={changePassword}>
            <TextField
              id="oldPassword"
              label="Current Password"
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={handleChange}
              value={formVals.oldPassword}
              type="password"
            />
            <TextField
              id="newPassword"
              label="New Password"
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={handleChange}
              value={formVals.newPassword}
              type="password"
            />
            <TextField
              id="confirmNewPassword"
              label="Confirm New Password"
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={handleChange}
              value={formVals.confirmNewPassword}
              type="password"
            />
            <Button
              variant="contained"
              className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: "500px",
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
  },
  actionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  actionSubtitle: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

export default withStyles(styles)(UserSettingsModal);
