import React, { Component } from 'react';
import DropArea from './DropArea';
import { connect } from 'react-redux';
import { updateCurrentEditProject, updateCurrentBrandDrop } from '../redux/actions/index';
import { withStyles } from '@material-ui/core/styles';
import AvailableBrands from './AvailableBrands';
import axios from 'axios';

function mapStateToProps(state) {
    return {
        currentEditProject: state.currentEditProject,
        currentBrandDrop: state.currentBrandDrop,
        currentAvailableBrands: state.currentAvailableBrands
    };
}

function mapDispatchToProps(dispatch) {
    return { 
        dispatch,
        updateCurrentEditProject,
        updateCurrentBrandDrop,
    }
}

const styles = theme => ({
    curBrandsTitle: {
        fontWeight: 500,
        marginBottom: 20
    },
})

class BrandEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandsInProject: [],
            availBrands: [],
        }
    }
    componentDidMount() {
        console.log('project id: ', this.props.projectId);
        this.setState({availBrands: this.props.currentAvailableBrands});
        axios.post(process.env.REACT_APP_SERVER_URL + '/project/get-brands', {projectId: this.props.projectId})
            .then(res => { this.setState({brandsInProject: res.data}); })
            .catch(err => console.log(err));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentBrandDrop !== this.props.currentBrandDrop) {
            this.state.brandsInProject.push(this.props.currentBrandDrop);
            this.setState({availBrands: this.props.currentAvailableBrands});
        }
    }
    render() {
        //const {classes} = this.props;
        return (
            <div>
                <div style={{ display: 'block', clear: 'both' }}>
                    <DropArea currentBrands={this.state.brandsInProject} projectId={this.props.projectId} />
                </div>
                <div style={{ display: 'block', clear: 'both' }}>
                    <AvailableBrands availBrands={this.state.availBrands} brandsInProject={this.state.brandsInProject} projectId={this.props.projectId} />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BrandEditor));