import React, { useContext } from 'react';
import Modal from 'react-modal';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box
} from '@material-ui/core';
import HTML5Backend from 'react-dnd-html5-backend';
import BrandEditor from '../../dnd/BrandEditor';
import { DragDropContextProvider } from 'react-dnd';
import CustomTooltip from '../../CustomTooltip';
import GlobalContext from '../../GlobalContext';
import axios from 'axios';

Modal.setAppElement("#root");

function ProjectEditModal(props) {
  const { classes } = props;
  const GLOBAL = useContext(GlobalContext)
  const deleteProject = () => {
    axios.post(process.env.REACT_APP_SERVER_URL + '/project/delete', { projectId: props.projectId })
      .then(res => {
        let newProjects = GLOBAL.projects.get.filter(f => f._id !== props.projectId);
        GLOBAL.projects.set(newProjects);
        props.closeModal();
      })
      .catch(err => console.log(err));
  }

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.closeModal()}
    >
      <DialogTitle>Edit Project <CustomTooltip helpText="Add a brand to this project by dragging it to the Current Brands box. Remove a brand by clicking the red trash can next to its name. Click the grey area outside this window to return to the app." /></DialogTitle>
      <DialogContent>
        <DialogContentText>
          Use this window to edit the brands associated with this project.
        </DialogContentText>
        <DragDropContextProvider backend={HTML5Backend}>
          <BrandEditor projectId={props.projectId} />
        </DragDropContextProvider>
        <Box style={{clear: 'both', height: 20}} />
        <Button
          variant='contained'
          color='secondary'
          className={classes.deleteButton}
          onClick={() => deleteProject()}
        >
          Delete Project
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const styles = theme => ({
  closeButton: {
    float: 'right',
    fill: '#e5e5e5',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    display: 'block',
    clear: 'both',
    color: 'white',
  },
  button: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
});

export default withStyles(styles)(ProjectEditModal);