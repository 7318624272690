import React, { useRef, useEffect, useState, useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import axios from 'axios';
import LoadingSpinner from '../ui/LoadingSpinner';
import * as d3 from 'd3';

function ConversationTopics(props) {
  // configuration variables
  const GLOBAL = useContext(GlobalContext);
  let d3Container = useRef(null);
  let preventFlicker = null;
  let margin = {
    top: 10,
    bottom: 10,
    left: 250,
    right: 250,
  };
  let width = 900 - margin.left - margin.right;
  //let height = 650 - margin.top - margin.bottom;
  let [graphData, setGraphData] = useState([]);

  // on page load and filter change
  useEffect(() => {
    setGraphData([]);
    d3.select(d3Container.current).select('svg').remove();
    let queryOptions = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };

    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/conversation-topics', { queryOptions })
      .then(res => {
        setGraphData(res.data);
      });
  }, [GLOBAL.currentDates.get.start,
    GLOBAL.currentDates.get.end,
    GLOBAL.currentBrands.get,
    GLOBAL.currentPlatforms.get,
    GLOBAL.excludeChildBrands.get,
    GLOBAL.boosted.get,
  ]);

  useEffect(() => {
    if (graphData.length > 0) {
      //console.log('raw graph data:', graphData);

      let nestedData = d3.nest()
        .key(d => d.brand)
        .key(d => d.general_resonance)
        .key(d => d.product_service)
        .rollup(d => d[0].count_product_service)
        .entries(graphData);

      //console.log('conversation topics nested:', nestedData);

      let hierarchy = d3.hierarchy({ values: nestedData }, d => d.values)
        .sum(d => d.value);
      
      //console.log('conversation topics hierarchy:', hierarchy);
        
      drawGraph(hierarchy);
    }
  }, [graphData]);

  function drawGraph(data) {
    // draw svg
    d3.select(d3Container.current).select('svg').remove();
    let svg = d3.select(d3Container.current)
      .append('svg')
        .attr('viewBox', [0, 0, 500, 500])
        .attr('width', 500)
        .attr('height', 500)
        .style('overflow', 'visible')
    let g = svg.append('g')
      .attr('transform', `translate(250,250)`);
    let radius = 500 / 6;
    let timeout = null;

    // graph functions
    let color = (brand) => {
      return GLOBAL.currentBrands.get.filter(b => (b.brand === brand))[0].color;
    }

    let partition = data => {
      return d3.partition()
        .size([2 * Math.PI, data.height + 1])
        (data);
    }

    let arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    let arcVisible = d => {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    let labelVisible = d => {
      let CHAR_SPACE = 7;
      let deltaAngle = (d.x1) - (d.x0);
      let r = Math.max(0, ((d.y0) + (d.y1)) / 2 * radius);
      let perimeter = r * deltaAngle;
      if (d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03) {
        if (d.label) return d.label.length * CHAR_SPACE < perimeter;
        else return false;
      }
      else return false;
    }

    let middleArc = d => {
      let halfPi = Math.PI / 2;
      let angles = [d.x0 - halfPi, d.x1 - halfPi];
      let middleAngle = (angles[1] + angles[0]) / 2;
      let invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
      if (invertDirection) { angles.reverse(); }
      let arcPath = d3.path();
      arcPath.arc(0,
        0,
        (d.y0 + d.y1) / 2 * radius,
        angles[0],
        angles[1],
        invertDirection);
      return arcPath.toString();
    }

    /* let textOffset = d => {
      let CHAR_SPACE = 6;
      let deltaAngle = d.x1 - d.x0;
      let r = Math.max(0, (d.y0 + d.y1) / 2 * radius);
      let perimeter = r * deltaAngle;
      let labelSpace = d.data.key.length * CHAR_SPACE;
      let perimOffset = (labelSpace / 2) / perimeter;
      console.log(d.data.key, deltaAngle, r, perimeter, labelSpace, perimOffset);
      if (perimOffset > .5) return '50%';
      else {
        let offsetNum = (.5 - perimOffset) * 100;
        let offsetStr = `${offsetNum}%`;
        return offsetStr;
      }
    }; */

    // partition data
    let root = partition(data);
    root.each(d => {
      d.current = d;
      d.label = d.data.key;
    });  // to help with click filtering

    // draw path
    let path = g.append('g')
      .selectAll('path')
      .data(root.descendants().slice(1))  // remove root node to replace below with clickable circle
      .join('path')
        .attr('fill', d => { while (d.depth > 1) d = d.parent; return color(d.data.key); })
        .attr('fill-opacity', d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
        .attr('d', d => arc(d.current))
        .attr('class', 'dataRect')
        .on('mouseover', tooltipOpen)
        .on('mousemove', tooltipMove)
        .on('mouseout', tooltipClose)
        .on('dblclick', d => {
          clearTimeout(timeout);
          clickHandle(d);
        });

    // only make slices clickable if there are children
    path.filter(d => d.children)
      .style('cursor', 'pointer')
      .on('click', d => {
        clearTimeout(timeout);
        timeout = setTimeout(() => clicked(d), 200)
      });

    // draw center "clickable" circle
    let parent = g.append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('click', clicked);

    g.append('foreignObject')
      .attr('width', radius * 1.5)
      .attr('height', radius * 1.5)
      .attr('id', 'instructions')
      .attr('transform', `translate(-${(radius / 2) + 20},-${radius / 2})`)
      .html('<div>Click to expand, double-click to view sentiment spectrum.</div>')
      .attr('pointer-events', 'none')
      .style('user-select', 'none')
      .attr('opacity', 0);

    let labelGroup = g.append('g')
      .attr('id', 'labelGroup')
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none");

    let textPaths = labelGroup.selectAll('path')
      .data(root.descendants().slice(1))
      .join('path')
      .attr('id', (_, i) => `hiddenArc${i}`)
      .attr('fill', 'none')
      .attr('fill-opacity', d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
      .attr('d', d => middleArc(d.current));

    let label = labelGroup.selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("fill-opacity", d => +labelVisible(d.current))

    label.append('textPath')
      .attr('startOffset', '50%')
      .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
      .text(d => d.label);

    // clicked function
    function clicked(p) {
      // assign parent's data
      parent.datum(p.parent || root);

      // revise root values
      root.each(d => d.target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth),
        label: d.label
      });

      // animation functions
      let t = g.transition().duration(500);
      path.transition(t)
        .tween('data', d => {
          let i = d3.interpolate(d.current, d.target);
          return t => d.current = i(t);
        })
        .filter(function (d) {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => arc(d.current));

      textPaths.transition(t)
        .tween('data', d => {
          let i = d3.interpolate(d.current, d.target);
          return t => d.current = i(t);
        })
        .filter(function (d) {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => middleArc(d.current));

      label.attr("fill-opacity", d => +labelVisible(d.target));
    }

    // draw legend
    /* svg.append('g')
        .attr('class', 'legend')
        .attr('id', 'ctLegend');
    let colorLegend = legend.legendColor()
        .scale(getBrandScale(props.projectBrands))
        .labelFormat(d3.format(".2f"));
    let legendNode = svg.select("#ctLegend")
        .call(colorLegend); 
    legendNode.attr('transform', 'translate('+(margin.left+width+10)+','+(margin.top+25)+')'); */
  }

  /* function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = tooltipWidth + 30,
      offsetY = tooltipHeight * 2 + 60;

    //console.log(x + margin.left + offsetX + tooltipWidth);
    //console.log(tooltipWidth)
    //console.log(width + margin.left + margin.right);
    
    if (x + margin.left + offsetX + tooltipWidth > width + margin.left + margin.right) offsetX = -(tooltipWidth/2);
    if (y + margin.top + offsetY + tooltipHeight < tooltipHeight + 15) offsetY = tooltipHeight * 3 + 80;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  } */

  function getSentimentScore(d) {
    //console.log('data:', d);
    
    // brand
    if (d.depth === 1) {
      let values = graphData.filter(f => f.brand === d.data.key).map(m => m.sentiment_score);
      return d3.mean(values);
    }

    // general_resonance
    if (d.depth === 2) {
      let values = graphData.filter(f => f.general_resonance === d.data.key && f.brand === d.parent.data.key).map(m => m.sentiment_score);
      return d3.mean(values);
    }

    // product_service
    if (d.depth === 3) {
      let values = graphData.filter(f => f.product_service === d.data.key && f.general_resonance === d.parent.data.key && f.brand === d.parent.parent.data.key).map(m => m.sentiment_score);
      return d3.mean(values);
    }

    return 0;
  }

  function tooltipOpen(d, i, n) {
    if (this.getAttribute('fill-opacity') > 0) {
      clearTimeout(preventFlicker);
      let pos = d3.mouse(this),
        share = ((d.current.x1 - d.current.x0) / (2 * Math.PI) * 100).toFixed(2),
        shareStr = `${share}%`,
        key = d.data.key,
        depth = d.depth,
        numComments = d.current.value || d.data.value,
        sentimentScore = getSentimentScore(d);
      //console.log('mouse position', pos);
      console.log('tooltip data:', d.current, d.data)
      let label = ((depth) => {
        switch (depth) {
          case 1: return 'Brand';
          case 2: return 'Conversation Topic';
          case 3: return 'Product/service';
          default: return 'Info';
        }
      })(depth);
      
      d3.select(d3Container.current)
        .select('svg')
        .append('foreignObject')
          .attr('id', 'tooltip')
          .attr('width', 1)
          .attr('height', 1)
          .attr('x', pos[0] + 260)
          .attr('y', pos[1] + 260)
          .style('overflow', 'visible')
          .html(`<div class="newTooltip">
                  <div class="tooltipLine"><span style="font-weight: bold">${label}: </span>${key}</div>
                  <div class="tooltipLine"><span style="font-weight: bold">Share of Voice: </span>${shareStr}</div>
                  <div class="tooltipLine"><span style="font-weight: bold">Number of Comments: </span>${numComments}</div>
                  <div class="tooltipLine"><span style="font-weight: bold">Avg. Sentiment Score: </span>${sentimentScore.toFixed(2)}</div>
                </div>`
          );
      
      //let dimensions = d3.select('.newTooltip').node().getBoundingClientRect();
        //offset = getOffset(pos, dimensions);

      //console.log('offset:', offset);
  
      /* d3.select('#tooltip')
        .attr('x', offset.x)
        .attr('y', offset.y) */

      d3.select('#instructions').attr('opacity', 1);
    }
  }

  function tooltipMove() {
    if (this.getAttribute('fill-opacity') > 0) {
      let pos = d3.mouse(this);
      /* let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
        offset = getOffset(pos, dimensions); */

      d3.select('#tooltip')
        .attr('x', pos[0] + 260)
        .attr('y', pos[1] + 260)
    }
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
    preventFlicker = setTimeout(() => d3.select('#instructions').attr('opacity', 0), 200);
  }

  function clickHandle(d) {
    let depth = d.depth;
    let brand = (depth >= 1) ? (() => { let n = d; while (n.depth > 1) n = n.parent; return n.data.key; })(d) : false;
    let convTopic = (depth >= 2) ? (() => { let n = d; while (n.depth > 2) n = n.parent; return n.data.key; })(d) : false;
    let productService = (depth >= 3) ? (() => { let n = d; while (n.depth > 3) n = n.parent; return n.data.key; })(d) : false;
    props.openModal(brand, convTopic, productService);
  }

  return (
    <div style={{ textAlign: 'center' }} >
      <div>
        {(graphData.length === 0) && <div style={{ margin: '20px' }}><LoadingSpinner /></div>}
        <div ref={d3Container} />
      </div>
    </div>
  );
}

export default ConversationTopics;