import { assignBrandColors } from './themes/CommonColors';

export function establishBrands(brands) {
  const parentBrands = brands.map(b => b.parentBrand);
  const brandColors = assignBrandColors(parentBrands);
  const newProjectBrands = brands.map(
    b =>
      ({
        brand: b.parentBrand,
        active: true,
        color: brandColors[b.parentBrand],
        children: b.childBrands,
      })
  ).sort((a, b) => a.brand > b.brand);

  //console.log('new project brands: ', newProjectBrands);
  return newProjectBrands;
}