import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

let width = 600;
let height = 300;
let margin = {
  top: 25,
  bottom: 25,
  left: 50,
  right: 25,
};

export default function PostSentimentSpectrum(props) {
  let d3Container = useRef(null);
  let [graphData, setGraphData] = useState();

  useEffect(() => {
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/sentiment-spectrum', { postId: props.postId })
      .then(result => {
        setGraphData(result.data);
        //console.log("Query result: ", props.postId, result.data);
      }) //console.log("Query result: ", props.postId, result); 
      .catch(err => console.log(err));
  }, [props.postId]);

  useEffect(() => {
    if (d3Container.current && graphData) {
      if (graphData.length > 0) {
        let svg = d3.select(d3Container.current)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.right})`);

        // draw x-axis
        let extent = d3.extent(graphData, d => new Date(d.date));
        let x = d3.scaleUtc()
          .domain(extent)
          .range([0, width]);
        let x_axis = d3.axisBottom(x)
          .tickFormat(d3.utcFormat("%b %d"));
        svg.append('g')
          .attr('class', 'x axis')
          .attr('transform', 'translate(0,' + (height / 2) + ')')
          .attr('fill', 'currentColor')
          .call(x_axis);

        // draw y-axis
        let y = d3.scaleLinear()
          .range([height, 0])
          .domain([-10, 10]);
        let y_axis = d3.axisLeft(y);
        svg.append('g')
          .attr('class', 'y axis')
          .attr('fill', 'currentColor')
          .call(y_axis);

        // draw x gridlines
        svg.append('g')
          .attr('class', 'grid')
          .call(d3.axisLeft(y)
            .tickSize(-(width))
            .tickFormat("")
          );
        svg.selectAll('.grid line')
          .style('stroke', '#e5e5e5');
        svg.select('.grid .domain')
          .attr('display', 'none');

        // define color scale
        let z = d3.scaleSequential(d3.interpolateRdYlGn)
          .domain([-10, 10]);

        // move labels to bottom
        svg.selectAll(".x .tick text")
          .attr('transform', `translate(0,${(height / 2)})`);

        // hide tick lines
        svg.selectAll(".x .tick line")
          .style('display', 'none');

        // draw data
        let g = svg.append('g');
        let dateGroups = g.selectAll('g')
          .data([...new Set(graphData.map(d => d.date))])
          .join('g');

        dateGroups.selectAll('circle')
          .data(date => {
            // only plot each unique date, sentiment score combination one time
            let scores = [...new Set(graphData.filter(d => date === d.date).map(d => d.sentiment_score))];
            let withDates = scores.map(d => { return { date: date, sentiment_score: d } });
            return withDates;
          })
          .join('circle')
          .attr("r", 5)
          .attr("cx", d => x(new Date(d.date)))
          .attr("cy", d => y(d.sentiment_score))
          .attr("fill", d => z(d.sentiment_score))
          .on('mouseover', tooltipOpen)
          .on('mousemove', tooltipMove)
          .on('mouseout', tooltipClose);

        function getOffset(pos) {
          let x = pos[0],
            y = pos[1],
            offsetX = 80,
            offsetY = -210;
          if (x + offsetX + 300 > width + margin.left + margin.right) offsetX = -310;
          if (y + offsetY < 0) offsetY = 10;
          let offsetReturn = {
            x: offsetX,
            y: offsetY
          };
          return offsetReturn;
        }

        function tooltipOpen(d, i, n) {
          let pos = d3.mouse(this),
            offset = getOffset(pos),
            strDate = d.date,
            rawScore = d.sentiment_score,
            score = rawScore.toFixed(2),
            filteredComments = graphData.filter(f => f.date === strDate && f.sentiment_score === rawScore).map(m => m.comment),
            numComments = filteredComments.length,
            comments = '';

          // build comment HTML
          filteredComments.forEach(c => {
            comments += `<li style="margin-left: 15px;">${c}</li>`
          })
          d3.select(d3Container.current)
            .append('foreignObject')
            .attr('id', 'tooltip')
            .attr('x', pos[0] + offset.x)
            .attr('y', pos[1] + offset.y)
            .attr('width', 300)
            .attr('height', 200)
            .html(`<div class="newTooltip">
                                    <span style="font-weight: bold">Score:</span> ${score}
                                    <br /><span style="font-weight: bold">${numComments} comment${(numComments > 1) ? 's' : ''}</span> on ${strDate}:
                                    <br />${comments}</div>`);
        }

        function tooltipMove() {
          let pos = d3.mouse(this);
          let offset = getOffset(pos);
          d3.select('#tooltip')
            .attr('x', pos[0] + offset.x)
            .attr('y', pos[1] + offset.y);
        }

        function tooltipClose() {
          d3.select('#tooltip').remove();
        }
      } else {
        let svg = d3.select(d3Container.current);
        let update = svg.append('g')
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        update.append('text')
          .text('Sentiment data is unavailable for this post.');
      }
    }
  }, [graphData]);

  return (
    <svg
      className="d3-component"
      id="spectrum"
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
      ref={d3Container}
    />
  );
}