import React, { useState, useContext } from 'react';
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  Popover,
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import {
  Create,
  Add,
  ChevronLeft,
  ChevronRight
} from '@material-ui/icons';
import axios from 'axios';
import ProjectCreateModal from '../components/ui/ProjectCreateModal';
import ProjectEditModal from '../components/ui/ProjectEditModal';
import GlobalContext from '../GlobalContext';
import * as fn from '../functions';

import ErrorMessage from '../components/ui/ErrorMessage';

const leftDrawerWidth = 140;

let SideDrawer = props => {
  const classes = useStyles(),
    GLOBAL = useContext(GlobalContext),
    [anchorEl, setAnchorEl] = useState(null),
    [createProjectOpen, setCreateProjectOpen] = useState(false),
    [projectEdit, setProjectEdit] = useState({
      open: false,
      id: null,
    });
  
  // error message state
  const [error, setError] = useState({
    open: false,
    message: ''
  })

  const selectProject = projectId => {
    //console.log(projectId);
    axios.post(process.env.REACT_APP_SERVER_URL + '/project/get-brands', { projectId: projectId })
      .then(
        brands => {
          //console.log('new brands:', brands.data);
          if (brands.data.length === 0) {
            setError({open: true, message: 'The project you selected contains no brands. Edit the project to add brands then try selecting it again.'})
            return;
          }
          GLOBAL.currentProjectId.set(projectId);
          GLOBAL.currentBrands.set(fn.establishBrands(brands.data));
        }
      ).catch(err => console.log(err));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Drawer
      className={classes.leftDrawer}
      variant="permanent"
      classes={{ paper: classes.leftDrawerPaper }}
      anchor="left"
    >
      <ErrorMessage
        open={error.open}
        errorMessage={error.message}
        close={() => setError({open: false, message: ''})}
      />
      <ProjectEditModal
        modalOpen={projectEdit.open}
        projectId={projectEdit.id}
        closeModal={() => {
          selectProject(projectEdit.id);
          setProjectEdit({ ...projectEdit, open: false });
        }}
      />
      <ProjectCreateModal
        open={createProjectOpen}
        close={() => setCreateProjectOpen(false)}
      />
      <div className={classes.toolbar} />
      <List component="nav">
        <ListItem button className={classes.listItemButton} aria-describedby={id} onClick={handleClick}>
          <Typography className={classes.listItemText}>
            Projects
          </Typography>
          <Typography>
            {open ? <ChevronLeft className={classes.iconChevron} /> : <ChevronRight className={classes.iconChevron} />}
          </Typography>
        </ListItem>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List component="nav">
            {(GLOBAL.projects.get.map(project => (
              <ListItem
                button
                className={classes.subMenuItem}
                style={{
                  backgroundColor: (project._id === GLOBAL.currentProjectId.get) ? 'rgba(40,185,209,0.2)' : null
                }}
                onClick={() => selectProject(project._id)}
                key={project._id}
              >
                <ListItemText primary={project.project_name} style={{ marginRight: 50 }} />
                <ListItemSecondaryAction
                  onClick={
                    () =>
                      setProjectEdit({ ...projectEdit, open: true, id: project._id })
                  }
                >
                  <IconButton edge="end">
                    <Create className={classes.icon} aria-label="edit" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )))}
            <ListItem 
              button 
              className={classes.subMenuItem}
              onClick={
                () => setCreateProjectOpen(true)
              }
            >
              <ListItemText primary="Create New" style={{ marginRight: 50 }} />
              <ListItemSecondaryAction>
                <IconButton edge="end">
                  <Add className={classes.iconAdd} aria-label="create-new" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Popover>
      </List>
    </Drawer>
  );
}

let useStyles = makeStyles(theme => ({
  leftDrawer: {
    width: leftDrawerWidth,
    flexShrink: 0,
  },
  leftDrawerPaper: {
    width: leftDrawerWidth,
    backgroundColor: '#ff7f00',
  },
  toolbar: theme.mixins.toolbar,
  listItemText: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1.1em',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2)
  },
  listItemButton: {
    padding: '0 !important',
  },
  subMenuItem: {
    padding: 10,
  },
  subMenuContents: {
    verticalAlign: 'middle',
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.54)',
  },
  iconAdd: {
    fill: 'rgba(0, 0, 0, 0.54)',
  },
  iconChevron: {
    fill: 'white',
    width: '16px',
    marginLeft: '2px',
    marginTop: '7px'
  }
}));

export default SideDrawer;