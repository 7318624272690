import React, { useRef, useEffect, useState, useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import * as d3 from 'd3';
import axios from 'axios';
import legend from 'd3-svg-legend';

function SentimentByTopic(props) {
  let d3Container = useRef(null);
  let [graphData, setGraphData] = useState();
  const GLOBAL = useContext(GlobalContext);

  useEffect(() => {
    let queryOptions = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brand: props.brand,
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };
    if (props.convTopic) queryOptions['convTopic'] = props.convTopic;
    if (props.productService) queryOptions['productService'] = props.productService;

    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/avg-sentiment-by-topic', { queryOptions })
      .then(result => setGraphData(result.data))
      .catch(err => console.log(err));

  }, [
    props.brand,
    GLOBAL.currentDates.get.start,
    GLOBAL.currentDates.get.end,
    GLOBAL.currentPlatforms.get,
    GLOBAL.boosted.get,
    GLOBAL.excludeChildBrands.get,
    props.convTopic,
    props.productService
  ]);

  useEffect(() => {
    if (d3Container.current && graphData) {
      if (graphData.length > 0) {
        let numCategories = graphData.map(d => d.topic).length;
        let width = 500;
        let height = (numCategories > 5) ? 200 : numCategories * 40;
        let margin = {
          top: 25,
          bottom: 25,
          left: 150,
          right: 100,
        };
        console.log(numCategories);
        let svg = d3.select(d3Container.current)
          .append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);

        let data = graphData;

        let absMax = d3.max(data, d => d.avg_sentiment),
          absMin = d3.min(data, d => d.avg_sentiment),
          rangeVal;

        if (absMin * -1 > absMax) rangeVal = absMin * -1;
        else if (absMax > absMin * -1) rangeVal = absMax;
        else rangeVal = absMax;
        rangeVal = Math.ceil(rangeVal);
        let xDomain = [-1 * rangeVal, rangeVal];

        const x = d3.scaleLinear()
          .range([0, width])
          .domain(xDomain);
        const x_axis = d3.axisBottom(x)
          .ticks(10);
        svg.append("g")
          .attr("class", "x axis")
          .attr('transform', `translate(0,${height})`)
          .attr("fill", "currentColor")
          .call(x_axis);

        // draw x gridlines
        /* svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left},0)`)
            .call(d3.axisLeft(y)
                .tickSize(-(width - margin.left - margin.right))
                .tickFormat("")
            );
 
        svg.selectAll('.grid line')
            .style('stroke', '#e5e5e5'); */

        const z = d3.scaleSequential(d3.interpolateRdYlGn)
          .domain([-10, 10]);

        const y = d3.scaleBand()
          .domain(data.map(d => d.topic))
          .range([0, height])
          .paddingInner(0.4);
        const y_axis = d3.axisLeft(y)
          .tickValues(data.map(d => d.topic));
        svg.append("g")
          .attr("class", "y axis")
          .attr("fill", "currentColor")
          .call(y_axis);

        svg.selectAll('rect')
          .data(data)
          .join('rect')
          .attr("x", d => (d.avg_sentiment < 0) ? x(d.avg_sentiment) : (width / 2))
          .attr("y", d => y(d.topic))
          .attr("height", y.bandwidth())
          .attr("width", d => (d.avg_sentiment < 0) ? ((width / 2) - x(d.avg_sentiment)) : ((x(d.avg_sentiment) - (width / 2))))
          .attr("fill", d => z(d.avg_sentiment));

        /* // move labels to bottom
        svg.selectAll(".x .tick text")
            .attr('transform', `translate(0,${(height / 2)})`)
            .call(wrap, x.bandwidth());
 
        // hide tick lines
        svg.selectAll(".x .tick line")
            .style('display', 'none'); */

        // draw legend
        svg.append('g')
          .attr('class', 'legend')
          .attr('id', 'ssLegend');
        let colorLegend = legend.legendColor()
          .scale(z);
        let legendNode = svg.select("#ssLegend")
          .call(colorLegend);
        let legendHeight = legendNode.node().getBoundingClientRect().height;
        legendNode.attr('transform', 'translate(' + (width + 20) + ',' + (height / 2 - legendHeight / 2) + ')');
      }
      else {
        let svg = d3.select(d3Container.current);
        svg.append('text')
          .text('Sentiment data is unavailable for this post.');
      }
    }
  }, [graphData]);
  return (
    <div ref={d3Container} />
  );
}

export default SentimentByTopic;