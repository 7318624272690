import React, { useRef, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import * as d3 from 'd3';
import legend from 'd3-svg-legend';
import {debounce} from 'lodash';
import { useLocation } from 'react-router-dom';

import GlobalContext from '../../GlobalContext';
import LoadingSpinner from '../ui/LoadingSpinner';

function SentimentScore(props) {
  // configuration variables
  const GLOBAL = useContext(GlobalContext),
    [graphData, setGraphData] = useState([]);
  let d3Container = useRef();
  let margin = {
    top: 50,
    bottom: 50,
    left: 50,
    right: 100,
  };
  //let width = 600 - margin.left - margin.right;
  let height = 200 - margin.top - margin.bottom;

  // on page load and filter change
  useEffect(() => {
    setGraphData([]);
    d3.select(d3Container.current).select('svg').remove();
    let queryOptions = {
      startDate: GLOBAL.currentDates.get.start.format('Y-MM-DD').toString(),
      endDate: GLOBAL.currentDates.get.end.format('Y-MM-DD').toString(),
      brands: GLOBAL.currentBrands.get.filter(f => f.active).map(m => m.brand),
      platforms: GLOBAL.currentPlatforms.get,
      boosted: GLOBAL.boosted.get,
      excludeChildBrands: GLOBAL.excludeChildBrands.get,
    };
    
    axios.post(process.env.REACT_APP_SERVER_URL + '/bigquery/responses-spectrum', { queryOptions })
      .then(res => {
        //console.log('sentiment score data: ', res.data);
        setGraphData(res.data);
      });
  }, [GLOBAL.currentDates.get.start,
      GLOBAL.currentDates.get.end,
      GLOBAL.currentBrands.get,
      GLOBAL.currentPlatforms.get,
      GLOBAL.excludeChildBrands.get,
      GLOBAL.boosted.get,
    ]);

  useEffect(() => {
    if (graphData.length > 0) drawChart(graphData);
  }, [graphData])

  const location = useLocation();

  // clean up event listeners on unmount
  useEffect(() => {
    return () => {
      d3.select(window).on(`resize.sentiment_score`, null);
    }
  }, [location])
  
  const getCurrentWidth = () => parseInt(d3.select(d3Container.current).node().parentNode.clientWidth) - margin.left - margin.right;
    
  const drawChart = data => {
    const svg = d3.select(d3Container.current)
      .append('svg')
        .attr('width', getCurrentWidth() + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
      .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('id', 'graphEdit')

    // compute range for scaling
    let absMax = d3.max(data, d => d.sentiment_score),
      absMin = d3.min(data, d => d.sentiment_score),
      rangeVal;
    if (absMin * -1 > absMax) rangeVal = absMin * -1;
    else if (absMax > absMin * -1) rangeVal = absMax;
    else rangeVal = absMax;
    rangeVal = Math.ceil(rangeVal);
    let yDomain = [-1 * rangeVal, rangeVal];

    const y = d3.scaleLinear()
      .range([height, 0])
      .domain(yDomain);
    const y_axis = d3.axisLeft(y)
      .ticks(6);
    svg.append("g")
      .attr("class", "y axis")
      .attr("fill", "currentColor")
      .call(y_axis);

    // draw x gridlines
    svg.append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft(y)
        .tickSize(-(getCurrentWidth()))
        .tickFormat("")
        .ticks(6)
      );

    svg.selectAll('.grid line')
      .style('stroke', '#e5e5e5');

    const z = d3.scaleSequential(d3.interpolateRdYlGn)
      .domain([-10, 10]);

    const x = d3.scaleBand()
      .domain(data.map(d => d.brand))
      .range([0, getCurrentWidth()])
      .paddingInner(0.4);
    const xAxis = d3.axisBottom(x)
      .tickValues(data.map(d => d.brand));
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(" + 0 + ", " + (height / 2) + ")")
      .call(xAxis);

    svg.selectAll(".dataRect")
      .data(data)
      .enter().append("rect")
        .attr("x", d => x(d.brand))
        .attr("y", d => (d.sentiment_score > 0) ? y(d.sentiment_score) : (height / 2))
        .attr("width", x.bandwidth())
        .attr("height", d => (d.sentiment_score > 0) ? ((height / 2) - y(d.sentiment_score)) : ((y(d.sentiment_score) - (height / 2))))
        .attr("fill", d => z(d.sentiment_score))
        .attr('class', 'dataRect')
        .on("mouseover", tooltipOpen)
        .on("mousemove", tooltipMove)
        .on("mouseout", tooltipClose)
        .on("click", clickHandle);

    // move labels to bottom
    svg.selectAll(".x .tick text")
      .attr('transform', `translate(0,${(height / 2)})`)
      .call(wrap, x.bandwidth());

    // hide tick lines
    svg.selectAll(".x .tick line")
      .style('display', 'none');

    // draw legend
    svg.append('g')
      .attr('class', 'legend')
      .attr('id', 'ssLegend');
    let colorLegend = legend.legendColor()
      .scale(z);
    let legendNode = svg.select("#ssLegend")
      .call(colorLegend);
    let legendHeight = legendNode.node().getBoundingClientRect().height;
    legendNode.attr('transform', 'translate(' + (getCurrentWidth() + 25) + ',' + (height / 2 - legendHeight / 2) + ')');
  
    const resize = () => {
      const update = d3.select(d3Container.current)
        .select('svg')
        .transition()
          .duration(500);
      
      update.attr('width', getCurrentWidth() + margin.left + margin.right)

      x.range([0, getCurrentWidth()]);
      update.select('.x.axis')
        .call(xAxis); 

      update.select('.grid')
        .call(d3.axisLeft(y)
          .tickSize(-(getCurrentWidth()))
          .tickFormat('')
          .ticks(6)
        );

      update.selectAll('.dataRect')
        .attr('width', x.bandwidth())
        .attr('x', d => x(d.brand))

      update.select('.legend')
        .attr('transform', `translate(${getCurrentWidth() + 25},${height / 2 - legendHeight / 2})`);
    }

    d3.select(window).on(`resize.sentiment_score`, debounce(resize, 300))
  }
  
  const wrap = (text, width) => {
    text.each(function () {
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em"),
        i = 0;
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width && i > 0) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
        i++;
      }
    });
  }

  function getOffset(pos, dimensions) {
    let x = pos[0],
      y = pos[1],
      tooltipWidth = dimensions.width,
      tooltipHeight = dimensions.height,
      offsetX = 15,
      offsetY = 15;

    //console.log(y + margin.top + offsetY + tooltipHeight);
    //console.log(height + margin.top + margin.bottom)
    
    if (x + margin.left + offsetX + tooltipWidth > getCurrentWidth() + margin.left + margin.right) offsetX = -tooltipWidth - 15;
    if (y + margin.top + offsetY + tooltipHeight > height + margin.top + margin.bottom) offsetY = -tooltipHeight;

    let offsetReturn = {
      x: x + offsetX,
      y: y + offsetY
    };
    
    return offsetReturn;
  }

  function tooltipOpen(d, i, n) {
    let pos = d3.mouse(this)
    
    let sentiment = d.sentiment_score;
    let numComments = d.num_comments;
      
    d3.select(d3Container.current)
      .select('#graphEdit')
      .append('foreignObject')
        .attr('id', 'tooltip')
        .attr('width', 1)
        .attr('height', 1)
        .style('overflow', 'visible')
        .html(`<div class="newTooltip">
                <div class="tooltipLine"><span style="font-weight: bold">Sentiment Score: </span>${sentiment}</div>
                <div class="tooltipLine"><span style="font-weight: bold">Number of Comments: </span>${numComments}</div>
              </div>`
        );
    
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipMove() {
    let pos = d3.mouse(this);
    let dimensions = d3.select('.newTooltip').node().getBoundingClientRect(),
      offset = getOffset(pos, dimensions);

    d3.select('#tooltip')
      .attr('x', offset.x)
      .attr('y', offset.y) 
  }

  function tooltipClose() {
    d3.select('#tooltip').remove();
  }
  
  function clickHandle(d, i, n) {
    props.openModal('sentiment_score', d.brand, 'global');
  }

  return (
    <div
      style={{height: height + margin.top + margin.bottom, position: 'relative'}}
    >
      {(graphData.length === 0) && <div style={{margin: '20px'}}><LoadingSpinner /></div>}
      <div ref={d3Container} style={{position: 'absolute', top: 0, left: 0, width: '100%'}} />
    </div>
  );
}

export default SentimentScore;