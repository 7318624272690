import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import BrandBox from './BrandBox';

function mapStateToProps(state) {
  return {
    currentBrandDrop: state.currentBrandDrop,
  };
}

const Types = {
  BRANDBOX: 'brandbox',
}

const styles = theme => ({
  curBrandsTitle: {
    fontWeight: 500,
    marginBottom: 20
  }
})

class AvailableBrands extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { connectDropTarget } = this.props;
    const { classes } = this.props;
    return (
      <div
        ref={connectDropTarget}
      >
        <Typography className={classes.curBrandsTitle}>Available Brands</Typography>
        {
          this.props.availBrands.map(b => (
            this.props.brandsInProject.indexOf(b.brand_name) < 0 &&
            <BrandBox name={b.brand_name} key={b.brand_name} projectId={this.props.projectId} />
          ))
        }
      </div>
    )
  }
}

AvailableBrands = DropTarget(
  Types.BRANDBOX,
  {
    drop: () => ({ name: 'Dustbin' }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(AvailableBrands);
AvailableBrands = withStyles(styles)(AvailableBrands);
AvailableBrands = connect(mapStateToProps)(AvailableBrands);
export default AvailableBrands;