import React from 'react';
import { Snackbar } from '@material-ui/core';

function ErrorMessage(props) {
  const {open, errorMessage, close} = props;

  return (
    <Snackbar
      open={open}
      onClose={() => close()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={errorMessage}
    />
  )
}

export default ErrorMessage;